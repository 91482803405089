import React from "react"
import { motion } from 'framer-motion'
import FieldLoader from "../FieldLoader"
import FieldInputLoader from "./FieldInputLoader"

const ProfileLoader = () => {
    return (
        <motion.div className="relative w-full h-full flex flex-col">
            <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                <motion.div className="px-5 my-2 font-bold w-28 h-4 animate-pulse bg-slate-700 rounded-full"></motion.div>
                <motion.div className="grid my-2 grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                </motion.div>
            </motion.div>
            <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                <motion.div className="px-5 my-2 font-bold w-28 h-4 animate-pulse bg-slate-700 rounded-full"></motion.div>
                <motion.div className="grid my-2 grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                </motion.div>
            </motion.div>
            <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                <motion.div className="px-5 my-2 font-bold w-28 h-4 animate-pulse bg-slate-700 rounded-full"></motion.div>
                <motion.div className="grid my-2 grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                    <FieldInputLoader />
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default ProfileLoader