import React from "react"
import { motion } from 'framer-motion'

const DashboardLoader = () => {
    return (
        <motion.div className="relative h-full my-2 w-4/4 flex flex-col">
            <motion.div className="flex flex-row w-full my-5 gap-10 justify-between">
                <motion.div className="grid grid-cols-2 w-2/4 gap-10 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
                    <motion.div className="w-60 bg-white rounded-2xl border shadow  p-4 max-w-sm w-full mx-auto flex flex-col justify-center px-5 h-24">
                        <motion.div className="flex flex-row justify-between">
                            <motion.div className="flex flex-col">
                                <motion.div className="h-5 rounded-full w-28 bg-slate-700 animate-pulse"></motion.div>
                                <motion.div className="h-5 rounded-full w-28  my-2 w-20 bg-slate-700 animate-pulse"></motion.div>
                            </motion.div>
                            <motion.div className="bg-slate-700 animate-pulse items-center bg-white rounded-xl h-12 w-12"></motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="w-60 bg-white rounded-2xl border shadow  p-4 max-w-sm w-full mx-auto flex flex-col justify-center px-5 h-24">
                        <motion.div className="flex flex-row justify-between">
                            <motion.div className="flex flex-col">
                                <motion.div className="h-5 rounded-full w-28 bg-slate-700 animate-pulse"></motion.div>
                                <motion.div className="h-5 rounded-full w-28  my-2 w-20 bg-slate-700 animate-pulse"></motion.div>
                            </motion.div>
                            <motion.div className="bg-slate-700 animate-pulse items-center bg-white rounded-xl h-12 w-12"></motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="w-60 bg-white rounded-2xl border shadow  p-4 max-w-sm w-full mx-auto flex flex-col justify-center px-5 h-24">
                        <motion.div className="flex flex-row justify-between">
                            <motion.div className="flex flex-col">
                                <motion.div className="h-5 rounded-full w-28 bg-slate-700 animate-pulse"></motion.div>
                                <motion.div className="h-5 rounded-full w-28  my-2 w-20 bg-slate-700 animate-pulse"></motion.div>
                            </motion.div>
                            <motion.div className="bg-slate-700 animate-pulse items-center bg-white rounded-xl h-12 w-12"></motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="w-60 bg-white rounded-2xl border shadow  p-4 max-w-sm w-full mx-auto flex flex-col justify-center px-5 h-24">
                        <motion.div className="flex flex-row justify-between">
                            <motion.div className="flex flex-col">
                                <motion.div className="h-5 rounded-full w-28 bg-slate-700 animate-pulse"></motion.div>
                                <motion.div className="h-5 rounded-full w-28  my-2 w-20 bg-slate-700 animate-pulse"></motion.div>
                            </motion.div>
                            <motion.div className="bg-slate-700 animate-pulse items-center bg-white rounded-xl h-12 w-12"></motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="border bg-white px-10 w-2/4 text-white relative rounded-2xl flex flex-col justify-center">
                    <motion.div className="flex flex-col">
                        <motion.div className="animate-pulse my-2 rounded-full bg-slate-700 h-4 w-36"></motion.div>
                        <motion.div className="animate-pulse my-2 rounded-full bg-slate-700 h-4 w-32"></motion.div>
                        <motion.div className="bg-slate-700  my-2 h-4 w-32 px-1 py-1 text-center w-32 bg-[#FFBB54]/10 rounded-xl"></motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
            <motion.div className="flex flex-row w-full my-5 gap-10 justify-between">
                <motion.div className="flex flex-col justify-center w-2/4  relative">
                    <motion.div className="grid grid-cols-2 gap-10 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
                        <motion.div className="w-60 rounded-2xl py-5 px-2 h-60 bg-white">
                            <motion.div className="flex flex-row justify-start">
                                <motion.div className={`h-12 w-12 animate-pulse bg-slate-700 rounded-full flex flex-col justify-center items-center text-center`}>
                                </motion.div>
                                <motion.div className="animate-pulse bg-slate-700  w-34 h-5 my-2 rounded-full flex-col justify-center mx-2 font-bold"></motion.div>
                            </motion.div>
                            <motion.div className="text-3xl animate-pulse bg-slate-700  w-34 h-5 my-10 rounded-full flex flex-col justify-center items-center"></motion.div>
                        </motion.div>
                        <motion.div className="w-60 rounded-2xl py-5 px-2 h-60 bg-white">
                            <motion.div className="flex flex-row justify-start">
                                <motion.div className={`h-12 w-12 animate-pulse bg-slate-700  rounded-full flex flex-col justify-center items-center text-center`}></motion.div>
                                <motion.div className="text-xl w-34 animate-pulse bg-slate-700  h-5 my-2 rounded-full flex flex-col justify-center mx-2 font-bold"></motion.div>
                            </motion.div>
                            <motion.div className="text-3xl w-34 h-5 animate-pulse bg-slate-700 my-10 rounded-full  flex flex-col justify-center items-center"></motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <motion.div className="bg-white px-10 w-2/4 z text-black relative rounded-2xl flex flex-col justify-center">
                    <motion.div className="flex flex-col">
                        <motion.div className="my-2 px-2 font-bold">
                            <motion.div className="flex my-1 flex-row">
                                <motion.div className="h-10 w-10 bg-slate-700 animte-pulse rounded-xl justify-center items-center flex flex-col bg-teal-800"></motion.div>
                                <motion.div className="justify-center  animate-pulse w-34 h-3 rounded-full bg-slate-700 items-center mx-2"></motion.div>
                            </motion.div>
                            <div className="w-full bg-slate-200 rounded-full">
                                <div className="bg-slate-700 animate-pulse text-xs h-3 font-medium text-white text-center p-0.5 leading-none rounded-full" style={{ width: `${(24717 % 10870) / 100}%` }}></div>
                            </div>
                            <div className="my-1 flex flex-row w-34 bg-slate-700 animate-pulse"></div>
                        </motion.div>
                        <motion.div className="my-2 px-2 font-bold">
                            <motion.div className="flex my-1 flex-row">
                                <motion.div className="h-10 w-10 bg-slate-700 animte-pulse rounded-xl justify-center items-center flex flex-col bg-teal-800"></motion.div>
                                <motion.div className="justify-center animate-pulse w-34 h-3 bg-slate-700 items-center flex flex-col mx-2"></motion.div>
                            </motion.div>
                            <div className="w-full bg-slate-200 rounded-full">
                                <div className="bg-slate-700 animate-pulse text-xs h-3 font-medium text-white text-center p-0.5 leading-none rounded-full" style={{ width: `${(24717 % 10870) / 100}%` }}></div>
                            </div>
                            <div className="my-1 flex flex-row w-34 bg-slate-700 animate-pulse"></div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div >
    )
}

export default DashboardLoader