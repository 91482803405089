import React, { useEffect, useLayoutEffect } from "react"
import { motion } from "framer-motion"
import SideBar from "../Components/SideBar"
import { CombinedRoutes } from "../Common/Routes"
import { Route, Switch } from 'react-router'
import { useDispatch, useSelector } from "react-redux"
import { setPageTitle } from "../Store/Theme/actions"
import { withRouter } from "react-router-dom"
import TopBar from "../Components/TopBar"

const getUrlElem = (url) => {
    let urlSplit = url.split('/')
    let route = CombinedRoutes.filter(c => c.link !== null && c.link.split('/').length === urlSplit.length).find(c => c.link.split('/').every((i, j) => i[0] === ':' || i === urlSplit[j]))
    let urlParams = {}
    route?.link?.split('/').forEach((c, i) => {
        if (c[0] === ':') {
            urlParams[c.slice(1,)] = urlSplit[i]
        }
    })
    let { component: Elem = null, title = null, ItemIcon: icon = null, saveData = false, multiWindow = true } = route || {}
    return { Elem, title, icon, saveData, multiWindow, urlParams }
}


const HomeRoot = ({ history }) => {

    const dispatch = useDispatch()
    const pageTitle = useSelector((state) => state.theme.pageTitle)

    // set page title
    useLayoutEffect(() => {
        let path = getUrlElem(history.location.pathname)
        if (path) dispatch(setPageTitle(path.title))
    }, [history.location]) // eslint-disable-line

    return (
        <motion.div className="min-h-screen backgroundImage md:px-5 flex">
            <motion.div className="py-5 hidden md:block">
                <SideBar />
            </motion.div>
            <div className="w-full flex flex-col h-full md:mx-10">
                <motion.div className="md:py-5 md:py-3">
                    <TopBar />
                </motion.div>
                <div className="text-left px-2 overflow-auto md:px-3 md:px-5 text-tpColor">
                    <motion.div className="text-xl md:text-[30px] mt-2 font-semibold text-white">
                        {pageTitle}
                    </motion.div>
                    <Switch>
                        {CombinedRoutes.map(c => (
                            <Route key={c.link} path={c.link} exact component={c.component} />
                        ))}
                    </Switch>
                </div>
            </div>
        </motion.div>
    )
}

export default withRouter(HomeRoot)