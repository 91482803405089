import { motion } from 'framer-motion'
import React from 'react'
import loginBg from '../Assets/Images/loginBg.svg'
import logo from '../Assets/Images/rsitelogo.png'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FieldInput from '../Components/FieldInput'
import Button from '../Components/Button'
import API from '../Common/API'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken, setLoggedIn, setUserDetails } from '../Store/Auth/actions';
import { setAlert } from '../Store/Theme/actions';

const initialFormValues = {
    refId: '',
    password: ''
}

const Login = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const loginFormSchema = Yup.object().shape({
        refId: Yup.string().required("Ref id is required"),
        password: Yup.string().required("Password is required")
    })

    const loginForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: loginFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            API.post('/auth/login', values).then(async (apiRes) => {
                if (apiRes.status === 200) {
                    localStorage.setItem('token', apiRes.data.data)
                    let userDetails = await JSON.parse(window.atob(apiRes.data.data.split(".")[1]));
                    dispatch(setUserDetails(userDetails))
                    dispatch(setToken(apiRes.data.data))
                    dispatch(setLoggedIn(true))
                    history.push('/')
                }
            }).catch(error => {
                dispatch(setAlert({ type: "error", title: 'Error', subtitle: error.response.data.error, active: true }))
            })
        }
    })

    return (
        <motion.div className="min-h-screen w-full flex flex-col">
            <motion.div className="flex flex-col md:flex-row-reverse justify-between flex-1">
                <motion.div className="flex flex-col w-full md:w-2/4 bg-lightBg justify-center items-center">
                    <img src={loginBg} className="h-48 md:h-64 w-full max-w-md" alt="Login Background" />
                </motion.div>
                <motion.div className="flex items-center w-full md:w-2/4 bg-white flex-col justify-center text-center">
                    <motion.div className="flex flex-row justify-start">
                        <img src={logo} className="h-20 w-20 md:h-24 md:w-24" alt="Logo" />
                        {/* <motion.div className="flex text-start justify-center flex-col ml-4">
                            <p className='text-headingColor text-2xl md:text-4xl font-bold'>
                                Freeminers
                            </p>
                            <p className='text-md md:text-lg font-semibold'>FREE COIN, THE FUTURE COIN</p>
                        </motion.div> */}
                    </motion.div>
                    <motion.div className="my-5 text-[15px] md:text-[20px] font-bold">
                        Log in to Freeminers
                    </motion.div>
                    <motion.div className="my-10 w-full flex flex-col items-center justify-center">
                        <FieldInput outerInjectClass='w-full max-w-md' label='Your RefId' placeholder="Enter your refId" fieldHelper={loginForm.getFieldHelpers('refId')} fieldMeta={loginForm.getFieldMeta('refId')} fieldProps={loginForm.getFieldProps('refId')} edit={true} dataLoaded={true} />
                        <motion.div className="flex flex-row px-4 md:px-32 w-full justify-end text-end">
                            <motion.div className="cursor-pointer hover:text-nameColor" onClick={() => history.push('/auth/forgotPassword')}>Forgot password ?</motion.div>
                        </motion.div>
                        <FieldInput outerInjectClass='w-full max-w-md' type="password" label='Your Password' placeholder="Enter your password" fieldHelper={loginForm.getFieldHelpers('password')} fieldMeta={loginForm.getFieldMeta('password')} fieldProps={loginForm.getFieldProps('password')} edit={true} dataLoaded={true} />
                        <Button outerWidth={'w-96'} type="primary" injectClass='rounded-lg ring-none w-full bg-nameColor mt-4' onClick={() => loginForm.submitForm()}>Sign In</Button>
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default Login