import { motion } from 'framer-motion'
import React, { useState } from 'react'
import loginBg from '../Assets/Images/loginBg.svg'
import logo from '../Assets/Images/rsitelogo.png'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FieldInput from '../Components/FieldInput'
import Button from '../Components/Button'
import API from '../Common/API'
import { useHistory } from 'react-router-dom';
import Dialog from '../Components/Dialog';
import OTPField from '../Components/OTPField';
import { useDispatch } from 'react-redux';
import { setAlert } from '../Store/Theme/actions';

const initialFormValues = {
    refId: '',
}

const initialPassValues = {
    refId: '',
    password: '',
    confirmPassword: ''
}

const ForgotPassword = () => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [otpDialog, setOtpDialog] = useState(false)
    const [resetForm, setResetForm] = useState(false)
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const refIdSchema = Yup.object().shape({
        refId: Yup.string().required("Ref id is required"),
    })

    const refForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: refIdSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            API.post('/auth/forgotPassword', values).then((apiRes) => {
                setOtpDialog(true)
            })
        }
    })

    const passFormShcema = Yup.object().shape({
        password: Yup.string().required("Password is required"),
        confirmPassword: Yup.string().required("Password is required")
    })

    const passForm = useFormik({
        initialValues: initialPassValues,
        validateOnMount: true,
        validationSchema: passFormShcema,
        enableReinitialize: true,
        onSubmit: (values) => {
            values.refId = refForm.values.refId
            if (values.password === values.confirmPassword) {
                API.post('/auth/resetPassword', values).then((apiRes) => {
                    if (apiRes.status === 200) {
                        dispatch(setAlert({ title: "Success", subtitle: "Password reset successful", active: true, type: 'success' }))
                        passForm.resetForm()
                        setResetForm(false)
                        refForm.resetForm()
                        history.push('/auth/login')
                    }
                })
            }
        }
    })

    const handleChange = (element, index) => {
        if (!/^\d*$/.test(element.value)) return; // Restrict to numbers only
        const updatedOtp = [...otp];
        updatedOtp[index] = element.value;
        setOtp(updatedOtp);

        // Move to the next input
        if (element.value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };


    const submitOTP = (e) => {
        e.preventDefault();
        let values = {
            refId: refForm.values.refId,
            otp: otp.join("")
        }
        API.post('/common/verifyOTP', values).then((verifyRes) => {
            if (verifyRes.status === 200) {
                dispatch(setAlert({ title: "Success", subtitle: "OTP Verified Successfully", active: true, type: "success" }))
                setOtpDialog(false)
                setResetForm(true)
            } else {
                dispatch(setAlert({ title: "Error", subtitle: "Incorrect OTP", active: true, type: "error" }))
            }
        }).catch((err) => {
            dispatch(setAlert({ title: "Error", subtitle: "Incorrect OTP", active: true, type: "error" }))
        })
    };


    const resendOTP = () => {
        API.post('/common/requestNewOTP', { refId: refForm.values.refId }).then((otpRes) => {
            setOtp(new Array(6).fill(""))
        })
    }

    return (
        <motion.div className="min-h-screen w-full flex flex-col">
            <motion.div className="flex flex-col md:flex-row-reverse justify-between flex-1">
                <motion.div className="flex flex-col w-full md:w-2/4 bg-lightBg justify-center items-center">
                    <img src={loginBg} className="h-48 md:h-64 w-full max-w-md" alt="Login Background" />
                </motion.div>
                <motion.div className="flex items-center w-full md:w-2/4 bg-white flex-col justify-center text-center">
                    <motion.div className="flex flex-row justify-start mb-5">
                        <img src={logo} className="h-24 w-24 md:h-24 md:w-24" alt="Logo" />
                        <motion.div className="flex text-start justify-center flex-col ml-4">
                            <p className='text-nameColor text-2xl md:text-4xl font-bold'>
                                Freeminers
                            </p>
                            <p className='text-md md:text-lg font-semibold'>FREE COIN, THE FUTURE COIN</p>
                        </motion.div>
                    </motion.div>
                    <motion.div className="my-5 text-xl md:text-2xl font-bold">
                        Forgot your password? 👋
                    </motion.div>
                    <motion.div className="my-5 w-96 text-sm md:text-md font-bold text-lightgrey">
                        Please enter the refId associated with your account and we will email you a password.
                    </motion.div>
                    {!resetForm && <motion.div className="my-10 w-full flex flex-col items-center justify-center">
                        <FieldInput outerInjectClass='w-full max-w-md' label='Your RefId' placeholder="Enter your refId" fieldHelper={refForm.getFieldHelpers('refId')} fieldMeta={refForm.getFieldMeta('refId')} fieldProps={refForm.getFieldProps('refId')} edit={true} dataLoaded={true} />
                        <Button outerWidth={'w-96'} type="primary" injectClass='rounded-lg ring-none w-full bg-nameColor mt-4' onClick={() => refForm.submitForm()}>Forgot Password</Button>
                        <Button outerWidth={'w-96'} type="primary" injectClass='rounded-lg ring-none w-full bg-nameColor bg-opacity-50 mt-4' onClick={() => history.push('/auth/login')}>Back to Login</Button>
                    </motion.div>}
                    {
                        resetForm && <motion.div className="my-10 w-full flex flex-col items-center justify-center">
                            <FieldInput outerInjectClass='w-full max-w-md' label='New Password' placeholder="New Password" fieldHelper={passForm.getFieldHelpers('password')} fieldMeta={passForm.getFieldMeta('password')} fieldProps={passForm.getFieldProps('password')} edit={true} dataLoaded={true} />
                            <FieldInput outerInjectClass='w-full max-w-md' label='Confirm Password' placeholder="Confirm Password" fieldHelper={passForm.getFieldHelpers('confirmPassword')} fieldMeta={passForm.getFieldMeta('confirmPassword')} fieldProps={passForm.getFieldProps('confirmPassword')} edit={true} dataLoaded={true} />
                            <Button outerWidth={'w-96'} type="primary" injectClass='rounded-lg ring-none w-full bg-nameColor mt-4' onClick={() => passForm.submitForm()}>Reset Password</Button>
                        </motion.div>
                    }
                </motion.div>
            </motion.div>
            <Dialog showDialog={otpDialog} title={'Verify Account'} onClose={() => setOtpDialog(false)}>
                {/* <OTPField onSucess={(ev) => ev && confirmOTP()} onAbort={() => { refForm.resetForm(); setOtpDialog(false) }} /> */}
                <div className="flex  w-full justify-center items-center h-96 bg-gray-100">
                    <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Enter OTP</h2>
                        <p className="text-gray-500 text-center mb-6">We’ve sent a code to your email Id</p>
                        <form onSubmit={submitOTP}>
                            <div className="flex justify-between space-x-2 mb-6">
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        id={`otp-input-${index}`}
                                        maxLength="1"
                                        value={data}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        className="w-12 h-12 text-center text-xl font-bold border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    />
                                ))}
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-nameColor text-white font-semibold rounded-md hover:bg-[nameColor]-700 transition duration-200"
                            >
                                Verify OTP
                            </button>
                        </form>
                        <p className="text-center text-gray-500 mt-4">
                            Didn’t receive the code?{" "}
                            <button
                                type="button"
                                className="text-blue-500 hover:underline"
                                onClick={() => resendOTP()}
                            >
                                Resend
                            </button>
                        </p>
                    </div>
                </div>
            </Dialog>
        </motion.div>
    )
}

export default ForgotPassword