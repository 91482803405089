import React from "react"
import { motion } from 'framer-motion'
import maintanenceImage from '../Assets/Images/maintanence.svg'
import { useSelector } from "react-redux"

const Maintanence = () => {

    const maintanence = useSelector(state => state.auth.maintanence)

    return (
        <motion.div className="relative min-h-screen w-full flex flex-col justify-center items-center">
            <motion.div className="flex flex-col items-center">
                <img src={maintanenceImage} height={500} width={500} />
                <p className="font-bold text-2xl">{maintanence.message !== '' && maintanence.message !== null && maintanence.message !== undefined ? maintanence.message : `We are under maintenance from ${maintanence.from} to ${maintanence.to}. Thank you!`}</p>
            </motion.div>
        </motion.div>
    )
}

export default Maintanence