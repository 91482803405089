import { AnimatePresence, motion } from "framer-motion";
import { IoMdClose } from "react-icons/io";

const Dialog = ({ children, showDialog, title, onClose, titleClass = '', injectClass = '' }) => {

    const close = () => {
        if (typeof close === 'function') onClose();
    }

    return (
        <AnimatePresence>
            {
                showDialog && <motion.div initial="hidden" animate="visible" exit="hidden" className="flex items-center text-black fixed top-0 bottom-0 left-0 right-0 justify-center bg-black bg-opacity-60 z-[900]">
                    <motion.div className={`bg-white w-full px-5 dark:bg-ldark sm:max-w-[300px] xs:max-w-[300px] lg:max-w-[1200px] xl:max-w-[1200px] 2xl:max-w-[1200px] md:max-w-[1200px] rounded-lg shadow-xl flex flex-col max-h-[90vh] ${injectClass}`}>
                        <motion.div className={`flex justify-between items-center ${titleClass}`} style={{ height: '35px' }}>
                            {title}
                            <IoMdClose size={'20px'} className="relative cursor-pointer font-bold text-sm text-[red] mx-2" onClick={close} />
                        </motion.div>
                        <motion.div className="flex mt-4 w-full overflow-auto">
                            {children}
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default Dialog