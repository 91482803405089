import BankDetails from "../Pages/Home/BankDetails"
import ChangePassword from "../Pages/Home/ChangePassword"
import Dashboard from "../Pages/Home/Dashboard"
import NoticeBoard from "../Pages/Home/NoticeBoard"
import PanDetails from "../Pages/Home/PanDetails"
import Profile from "../Pages/Home/Profile"
import Chart from "../Pages/Team/Chart"
import DirectReferrals from "../Pages/Team/DirectReferrals"
import TransferWalletReport from '../Pages/WalletReports/TransferWalletReport'
import StakingWalletReport from "../Pages/WalletReports/StakingWalletReport"
import EarningWalletReport from '../Pages/WalletReports/EarningWalletReport'
import OfferWalletReport from '../Pages/WalletReports/OffersWalletReport'
import Staking from "../Pages/Transactions/Staking"
import Withdraw from "../Pages/Transactions/Withdraw"
import TransferWallet from "../Pages/Transactions/TransferWallet"
import WithdrawReport from "../Pages/TransactionReports/WithdrawReport"
import StakingReport from "../Pages/TransactionReports/StakingReport"
import AddUser from "../Pages/Settings/AddUser"
import ReferralLink from "../Pages/Settings/ReferralLink"
import Plan from "../Pages/Settings/Plan"
import Support from "../Pages/Settings/Support"
import ExternalAddUser from "../Pages/Settings/ExternalAddUser"

export const HomeRoutes = [
    {
        title: 'Dashboard',
        link: '/' || '/dashboard',
        sidebar: true,
        menu: 'Home',
        component: Dashboard
    },
    {
        title: 'Profile',
        link: '/profile',
        menu: 'Home',
        sidebar: true,
        component: Profile
    },
    // {
    //     title: 'Bank Details',
    //     link: '/bank-details',
    //     menu: 'Home',
    //     component: BankDetails
    // },
    {
        title: 'Change Password',
        link: '/change-password',
        menu: 'Home',
        sidebar: true,
        component: ChangePassword
    },
    {
        title: 'PAN Details',
        link: '/pan-details',
        menu: 'Home',
        sidebar: true,
        component: PanDetails
    },
    {
        title: 'Notice Board',
        link: '/notice-board',
        menu: 'Home',
        sidebar: true,
        component: NoticeBoard
    }
]

export const TeamRoutes = [
    {
        title: 'Direct Referrals',
        link: '/direct-referrals',
        menu: 'Team',
        sidebar: true,
        component: DirectReferrals
    },
    {
        title: 'Chart View',
        link: '/chart-view',
        menu: 'Team',
        sidebar: true,
        component: Chart
    }
]

export const WalletReportRoutes = [{
    title: 'Staking Wallet Report',
    link: '/staking-wallet-report',
    menu: 'Wallet Report',
    sidebar: true,
    component: StakingWalletReport
},
{
    title: 'Earning Wallet Report',
    link: '/earning-wallet-report',
    menu: 'Wallet Report',
    sidebar: true,
    component: EarningWalletReport
},
{
    title: 'Offers Wallet Report',
    link: '/offer-wallet-report',
    menu: 'Wallet Report',
    sidebar: true,
    component: OfferWalletReport
},
{
    title: 'Transfer Wallet Report',
    link: '/transfer-wallet-report',
    menu: 'Wallet Report',
    sidebar: true,
    component: TransferWalletReport
}]

export const TransactionRoutes = [
    {
        title: 'Staking',
        link: '/staking',
        sidebar: true,
        menu: 'Transactions',
        component: Staking,
    },
    {
        title: 'Withdraw',
        link: '/withdraw',
        menu: 'Transactions',
        sidebar: true,
        component: Withdraw,
    },
    {
        title: 'Transfer-wallet',
        link: '/transfer-wallet',
        menu: 'Transactions',
        sidebar: true,
        component: TransferWallet
    }
]

export const TransactionReports = [
    {
        title: 'Withdraw Report',
        link: '/withdraw-report',
        menu: 'Transactions Reports',
        sidebar: true,
        component: WithdrawReport,
    },
    {
        title: 'Staking Report',
        link: '/staking-report',
        menu: 'Transactions Reports',
        sidebar: true,
        component: StakingReport
    }
]

export const SettingsRoutes = [
    {
        title: 'Add User',
        link: "/add-user",
        menu: 'Settings',
        sidebar: true,
        component: AddUser
    },
    {
        title: 'Referral Link',
        link: '/referral-link',
        menu: 'Settings',
        sidebar: true,
        component: ReferralLink
    },
    {
        title: 'Plan',
        link: '/plan',
        menu: 'Settings',
        sidebar: true,
        component: Plan
    },
    {
        title: 'Support',
        link: '/support',
        menu: 'Settings',
        sidebar: true,
        component: Support
    },
    {
        title: "Add User",
        link: '/referral-link/:parentRefId/:nodePosition',
        menu: 'Settings',
        sidebar: false,
        component: ExternalAddUser
    }
]

export const CombinedRoutes = [...HomeRoutes, ...TeamRoutes, ...WalletReportRoutes, ...TransactionRoutes, ...TransactionReports, ...SettingsRoutes]