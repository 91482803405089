import React, { useState } from "react";
import API from "../Common/API";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlert } from "../Store/Theme/actions";

const OTPField = ({ onSucess, onAbort }) => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const userDetails = useSelector((state) => state.auth.userDetails)
    const dispatch = useDispatch()

    const handleChange = (element, index) => {
        if (!/^\d*$/.test(element.value)) return; // Restrict to numbers only
        const updatedOtp = [...otp];
        updatedOtp[index] = element.value;
        setOtp(updatedOtp);

        // Move to the next input
        if (element.value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let values = {
            refId: userDetails.subject.refId,
            otp: otp.join("")
        }
        API.post('/common/verifyOTP', values).then((verifyRes) => {
            console.log(verifyRes)
            if (verifyRes.status === 200) {
                dispatch(setAlert({ title: "Error", subtitle: "OTP Verified Successfully", active: true, type: "success" }))
                onSucess(true)
            } else {
                dispatch(setAlert({ title: "Error", subtitle: "Incorrect OTP", active: true, type: "error" }))
            }
        }).catch((err) => {
            dispatch(setAlert({ title: "Error", subtitle: "Incorrect OTP", active: true, type: "error" }))
        })
    };


    const resendOTP = () => {
        API.get('/common/requestOTP').then((otpRes) => {
            setOtp(new Array(6).fill(""))
        })
    }

    return (
        <div className="flex  w-full justify-center items-center h-96 bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Enter OTP</h2>
                <p className="text-gray-500 text-center mb-6">We’ve sent a code to your email Id</p>
                <form onSubmit={handleSubmit}>
                    <div className="flex justify-between space-x-2 mb-6">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="text"
                                id={`otp-input-${index}`}
                                maxLength="1"
                                value={data}
                                onChange={(e) => handleChange(e.target, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                className="w-12 h-12 text-center text-xl font-bold border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        ))}
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-nameColor text-white font-semibold rounded-md hover:bg-[nameColor]-700 transition duration-200"
                    >
                        Verify OTP
                    </button>
                </form>
                <p className="text-center text-gray-500 mt-4">
                    Didn’t receive the code?{" "}
                    <button
                        type="button"
                        className="text-blue-500 hover:underline"
                        onClick={() => resendOTP()}
                    >
                        Resend
                    </button>
                </p>
            </div>
        </div>
    );
};

export default OTPField;