const authStore = {
    isLoggedIn: true,
    userDetails: {},
    token: {},
    menuItems: {},
    orgId: '',
    maintanence: {},
    coinValue: 0
}

export default authStore