import React, { useEffect, useMemo, useRef, useState } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { IoIosAlert } from "react-icons/io";
import { GiCheckMark } from 'react-icons/gi'
import { createPortal } from "react-dom";
import { RiArrowDownSLine } from 'react-icons/ri'

const SelectOptionAnim = {
    hidden: {
        height: 0,
        opacity: 0
    },
    visible: {
        height: 'auto',
        opacity: 1,
        zIndex: 10000,
    }
}

const FieldSelect = ({ label, injectClass, edit, fieldProps, fieldMeta, fieldHelper, options, loading = false, dataLoaded = false, onChange, disabled = false, arrowEnd = false, fullWidth = false, title }) => {

    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [filter, setFilter] = useState('')
    const [focussed, setFocussed] = useState(false)
    const [pos, setPos] = useState({ width: 0, top: 0, left: 0 })
    // const history = useHistory()

    const makeFocus = () => {
        if (input.current !== null)
            input.current.focus();
    }

    const toggleFocus = () => {
        if (focussed) {
            setFilter('')
            if (input.current !== null) input.current.blur()
        }
        setFocussed(!focussed)
        document.body.removeEventListener('click', toggleFocus)
    }

    const handleBlur = () => {
        setTimeout(() => {
            if (focussed) toggleFocus()
        }, 200)
    }

    useEffect(() => {
        if (focussed) {
            // document.body.addEventListener('click', toggleFocus)
            let e = wrapRef.current.getBoundingClientRect()
            let o = { width: `${e.width}px`, left: `${e.x}px` }
            if ((e.top + e.height + 270) > window.innerHeight) {
                o.bottom = `${window.innerHeight - e.top}px`
            } else {
                o.top = `${(e.top + e.height)}px`
            }
            setPos(o)
        }

        return () => document.body.removeEventListener('click', toggleFocus)
    }, [focussed]) //eslint-disable-line

    const toggleError = (val) => setShowError(val)

    const setValue = (value) => {
        fieldHelper.setValue(value)
        if (typeof onChange === 'function') onChange(value)
    }

    const filteredOptions = useMemo(() => {
        return options.filter(c => (filter === null || filter.trim() === '' || c.text.toString().toLowerCase().trim().includes(filter.toLowerCase().trim())))
    }, [filter, options])//eslint-disable-line

    const displayText = useMemo(() => {
        let a = options.filter(c => c.value === fieldProps.value)
        return a.length > 0 ? a[0].text : false
    }, [fieldProps.value, options])//eslint-disable-line

    return (
        <motion.div className={`relative mt-2 mb-3 p-5`}>
            <motion.div className={`${disabled ? 'bg-[lightGrey] ring-1  ring-[grey]' : 'bg-pColor'} absolute -top-3 left-2 px-5 drop-shadow-sm rounded w-auto text-tPColor`}>
                {label}
            </motion.div>
            <motion.div className={`relative ring-1 ${injectClass} drop-shadow-lg ring-[lightgrey] rounded h-11 px-2 py-2 w-full flex flex-col ${disabled ? 'bg-[lightGrey]' : 'bg-transparent'}`}>
                <div ref={wrapRef} className={` ${injectClass} group relative flex flex-col ${disabled ? 'bg-[lightGrey]' : 'bg-transparent'} rounded-lg ${focussed ? ' focus-within:ring-black dark:focus-within:ring-tcolor' : ''} transition-all duration-100 cursor-pointer ${loading ? 'animate-pulse' : ''}`} onClick={makeFocus}>
                    <div className="relative p-1">
                        {!edit && dataLoaded &&
                            <div className={`pb-2 h-8 ${fieldProps.value ? '' : 'text-gray-500 line-through'}`}>{displayText || ' '}</div>
                        }
                        {
                            !dataLoaded &&
                            <div className="w-3/4 h-2 my-2 rounded-lg animate-pulse bg-gray-300"></div>
                        }
                        {
                            edit && dataLoaded &&
                            <>
                                <div className={`pb-3 pl-3 overflow-hidden text-start h-7 ${title || label || fullWidth ? 'w-full' : 'w-12'}  ${focussed ? 'hidden' : ''}`}>{displayText || `${label || title ? `Select ${label ? label : title ? title : ''}` : ''}`}</div>
                                <input ref={input} disabled={disabled} className={`pl-3 bg-transparent pr-10 w-full outline-none ${!focussed ? 'h-0 w-0 absolute' : 'h-7'}`} type="text" value={filter} autoComplete="off" onFocus={toggleFocus} onBlur={handleBlur} onChange={ev => setFilter(ev.target.value)} />
                            </>
                        }
                        {
                            showError ? <motion.div className={'absolute flex top-0 right-2 w-60 px-2 rounded py-2 h-7 text-center align-center items-center bg-pColor text-[red]'}>{fieldMeta.error}</motion.div> : ""
                        }
                        {edit &&
                            <span className={`${arrowEnd ? 'right-2' : 'right-5'} absolute  top-1 text-xl flex`}>
                                {edit && fieldMeta.error && fieldMeta.touched ? <IoIosAlert className='w-8 cursor-pointer text-[red]' onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} /> : <RiArrowDownSLine className="text-gray-400" />}
                            </span>
                        }
                    </div>
                </div>
                {
                    createPortal(
                        <AnimatePresence>
                            {
                                focussed &&
                                <motion.div variants={SelectOptionAnim} initial="hidden" animate="visible" exit="hidden" className={`fixed overflow-y-auto max-h-[270px] bg-white dark:bg-mdark ring-1 ring-[grey] dark:ring-ldark p-2 rounded`} style={pos}>
                                    {
                                        filteredOptions.map((c, i) => (
                                            <div key={i} onClick={() => setValue(c.value)} className={`py-2 px-2 cursor-pointer hover:bg-sColor ${c.value === fieldProps.value ? 'text-black' : 'text-black'} hover:bg-black hover:text-white rounded-lg flex justify-between items-center`}>
                                                {c.text}
                                                {c.value === fieldProps.value && <GiCheckMark className="text-[green]" />}
                                            </div>
                                        ))
                                    }
                                    {filteredOptions.length === 0 && <span className="block px-5 pb-2 text-gray-500"> </span>}
                                </motion.div>
                            }
                        </AnimatePresence>, document.body)
                }
            </motion.div>
        </motion.div>
    )
}

export default FieldSelect