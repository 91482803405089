import React, { useState, useEffect, useMemo } from "react"
import { motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import API from "../../Common/API"
import { useFormik } from "formik"
import * as Yup from 'yup'
import FieldInput from "../../Components/FieldInput"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"

const initialPanForm = {
    panName: "",
    panCardNo: "",
    panProof: "",
    refId: "",
    userId: ""
}


const PanDetails = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [panDetails, setPanDetails] = useState()
    const userDetails = useSelector((state) => state.auth.userDetails)

    const formData = useMemo(() => {
        return { ...initialPanForm, ...panDetails }
    }, [panDetails])

    useEffect(() => {
        setLoading(true)
        API.get(`/user/getPanDetails/${userDetails.subject._id}`).then((panRes) => {
            if (panRes.status === 200) {
                setPanDetails(panRes.data.data)
                setLoading(false)
            }
        })
    }, [count])

    const panFormSchema = Yup.object().shape({
        panName: Yup.string(),
        panCardNo: Yup.string(),
        panProof: Yup.string(),
        refId: Yup.string(),
        userId: Yup.string()
    })

    const panForm = useFormik({
        initialValues: formData,
        validateOnMount: true,
        validationSchema: panFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values)
            values.userId = userDetails.subject._id
            values.refId = userDetails.subject.refId
            API.post('/user/uploadPanDetails', values).then((panRes) => {
                if (panRes.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Pan Updated Successfully', active: true, type: 'success' }))
                    setCount(count + 1)
                }
            })
        }
    })

    return (
        <motion.div className="h-full w-full bg-white rounded-xl relative flex flex-col">
            <motion.div className="grid grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 py-5 px-2">
                <FieldInput label="Name" placeholder={'As Per PAN CARD'} fieldProps={panForm.getFieldProps('panName')} fieldMeta={panForm.getFieldMeta('panName')} fieldHelper={panForm.getFieldHelpers('panName')} edit={true} dataLoaded={true} />
                <FieldInput label="PAN No" placeholder={'CAW00000'} fieldProps={panForm.getFieldProps('panCardNo')} fieldMeta={panForm.getFieldMeta('panCardNo')} fieldHelper={panForm.getFieldHelpers('panCardNo')} edit={true} dataLoaded={true} />
                <FieldInput type="file" label="Upload PAN" fieldProps={panForm.getFieldProps('panProof')} fieldMeta={panForm.getFieldMeta('panProof')} fieldHelper={panForm.getFieldHelpers('panProof')} edit={true} dataLoaded={true} />
                <motion.div className='flex flex-row justify-center'>
                    <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => panForm.submitForm()}>Update</Button>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default PanDetails