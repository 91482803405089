import React, { useState } from "react"
import { motion } from 'framer-motion'
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { useFormik } from "formik"
import FieldInput from "../../Components/FieldInput"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import API from "../../Common/API"
import Dialog from "../../Components/Dialog"
import OTPField from "../../Components/OTPField"

const initialPassForm = {
    newPassword: '',
    confirmPassword: ''
}

const ChangePassword = () => {

    const dispatch = useDispatch()
    const [otpDialog, setOtpDialog] = useState(false)

    const passwordFormSchema = Yup.object().shape({
        newPassword: Yup.string().required("New Password is required"),
        confirmPassword: Yup.string().required("Confirm Password is required")
    })

    const passwordForm = useFormik({
        initialValues: initialPassForm,
        validateOnMount: true,
        validationSchema: passwordFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setOtpDialog(false)
            if (values.newPassword === values.confirmPassword) {
                API.post('/auth/changePassword', values).then((passRes) => {
                    if (passRes.status === 200) {
                        dispatch(setAlert({ title: 'Success', subtitle: 'Password changed successfully', active: true, type: 'success' }))
                        passwordForm.resetForm()
                    }
                })
            }
        }
    })

    const requestOTP = () => {
        API.get('/common/requestOTP').then((otpRes) => {
            setOtpDialog(true)
        })
    }

    return (
        <motion.div className="h-full w-full bg-white rounded-xl relative flex flex-col">
            <motion.div className="grid grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 py-5 px-2">
                <FieldInput type="password" label="New Password" placeholder={'New Password'} fieldProps={passwordForm.getFieldProps('newPassword')} fieldMeta={passwordForm.getFieldMeta('newPassword')} fieldHelper={passwordForm.getFieldHelpers('newPassword')} edit={true} dataLoaded={true} />
                <FieldInput type="password" label="Confirm Password" placeholder={'Confirm Password'} fieldProps={passwordForm.getFieldProps('confirmPassword')} fieldMeta={passwordForm.getFieldMeta('confirmPassword')} fieldHelper={passwordForm.getFieldHelpers('confirmPassword')} edit={true} dataLoaded={true} />
                <motion.div className='flex flex-row justify-center'>
                    <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => requestOTP()}>Update</Button>
                </motion.div>
            </motion.div>
            <Dialog title={'Verify Confirmation'} showDialog={otpDialog} onClose={() => { setOtpDialog(false) }}>
                <OTPField onSucess={(ev) => { ev && passwordForm.submitForm() }} onAbort={() => { passwordForm.resetForm(); setOtpDialog(false) }} />
            </Dialog>
        </motion.div>
    )
}

export default ChangePassword