import React, { useState, useEffect, useMemo } from "react"
import { motion } from 'framer-motion'
import { useDispatch } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import API from "../../Common/API"
import InlineTextField from "../../Components/InLineTextField"
import DataTable, { createTheme } from "react-data-table-component"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTableLoader from '../../Components/Loaders/DataTableLoader'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const WithDrawReport = () => {

    const dispatch = useDispatch()
    const [reports, setReports] = useState([])
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    useEffect(() => {
        API.get('/reports/withDrawReport').then((res) => {
            if (res.status === 200) {
                setReports(res.data.data)
                setLoading(false)
            }
        })
    }, [])


    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            { name: 'RefId', selector: row => row.refId, initialActive: true, sortable: true },
            { name: 'Parent RefId', selector: row => row.parentRefId, sortable: true },
            { name: 'Amount', selector: row => row.amount, sortable: true },
            { name: 'Coins', selector: row => row.coins, sortable: true },
            { name: 'Date', selector: row => row.createdAt.toString().slice(0, 10), sortable: true },
            { name: 'Status', selector: row => { return row.status === 'Pending' ? <motion.div className="bg-[blue] text-white  flex flex-row justify-center px-2 py-1 rounded-full w-20">Pending</motion.div> : row.status === 'Decline' ? <motion.div className="bg-[red] text-white px-2 py-1 rounded-full w-20 flex flex-row justify-center">Decline</motion.div> : <motion.div className="bg-[green] text-white px-2 py-1 rounded-full w-20 flex flex-row justify-center">Approved</motion.div> }, sortable: true },
        ]
    })

    const filteredReportDetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return reports.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [reports, filter])/// eslint-disable-line


    const SubHeader = useMemo(() => (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-5">
            <motion.div className="flex flex-row">
                <p>Internal Withdraw </p>&nbsp;:&nbsp;<p className="text-primary font-bold text-xl">
                    {reports
                        .filter((x) => x.withDrawType === 'internalTransfer')
                        .reduce((total, c) => parseInt(total) + parseInt(c.amount), 0)}
                </p>
            </motion.div>
            <motion.div className="flex flex-row">
                <p>Normal Withdraw </p>&nbsp;:&nbsp;<p className="text-primary font-bold text-xl">
                    {reports
                        .filter((x) => x.withDrawType === 'withdraw')
                        .reduce((total, c) => parseInt(total) + parseInt(c.amount), 0)}
                </p>
            </motion.div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter, reports]) // eslint-disable-line

    return (
        <>
            {
                loading ? <DataTableLoader /> : <DataTable title="Transfer Wallet Report" theme="defaultLight" columns={columns} data={filteredReportDetails}
                    pagination noHeader highlightOnHover persistTableHead subHeader
                    subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
            }
        </>
    )
}

export default WithDrawReport