import React, { useEffect, useRef, useState } from "react";
import { OrgChart } from "d3-org-chart";
import dummyProfile from "../../Assets/Images/dummyProfile.jpeg";
import API from "../../Common/API";
import Button from "../../Components/Button";
import { useSelector } from "react-redux";

const Chart = () => {
    const chartRef = useRef(null);
    const [chart, setChart] = useState(null);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState("");
    const userDetails = useSelector(state => state.auth.userDetails)

    // Fetch data from the server
    const fetchChartData = () => {
        API.get("/chart/getChart")
            .then((chartRes) => {
                if (chartRes.status === 200) {
                    const responseData = chartRes.data.data;
                    const structuredData = responseData.map((node) => ({
                        id: node.id,
                        name: node.name || `User ${node.id}`,
                        refId: node.refId || `Ref-${node.id}`,
                        position: node.position || "Position Not Specified",
                        activeStat: node.activeStat,
                        image: node.image || dummyProfile,
                        parentId: node.parentId || "",
                    }));
                    setData(structuredData);
                }
            })
            .catch((error) => {
                console.error("Error fetching chart data:", error);
            });
    };

    useEffect(() => {
        fetchChartData();
    }, []);

    useEffect(() => {
        if (data.length > 0 && chartRef.current) {
            const orgChart = new OrgChart()
                .nodeHeight(() => 110)
                .nodeWidth(() => 220)
                .childrenMargin(() => 50)
                .compactMarginBetween(() => 35)
                .compactMarginPair(() => 30)
                .neighbourMargin(() => 20)
                .nodeContent((d) => {
                    const color = d._highlighted ? "#E6F7FF" : "#FFFFFF";
                    return `
    <div style='width:${d.width}px;height:${d.height}px;padding-top:25px;padding-left:1px;padding-right:1px' data-id="${d.data.id}">
        <div style="font-family: 'Inter', sans-serif;background-color:${color}; margin-left:-1px;width:${d.width - 2}px;height:${d.height - 25}px;border-radius:10px;border: 1px solid #E4E2E9">
            <div style="display:flex;justify-content:flex-end;margin-top:5px;margin-right:8px;font-weight:bold">#${d.data.refId}</div>
            <div style="background-color:${color};margin-top:-45px;margin-left:15px;border-radius:100px;width:50px;height:50px;"></div>
            <div style="margin-top:-45px;">   
                <img 
                src="${d.data.image}" 
                style="margin-left:20px;border-radius:100px;width:40px;height:40px;border: 3px solid ${d.data.activeStat === 'A' ? '#4CAF50' : '#F44336'};" 
            />
            </div>
            <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:10px">  ${d.data.name}</div>
        </div>
    </div>
`;
                })
                .container(chartRef.current)
                .data(data)
                .render();

            setChart(orgChart);
        }
    }, [data]);

    const handleFilterChange = (value) => {

        if (chart) {
            if (!value.trim()) {
                // Reset the chart view to its initial state when filter is empty
                chart.setCentered(null).render();
                return;
            }

            const filteredData = data.map((node) => {
                node._expanded = false;
                node._highlighted = false;

                if (value && node.refId.toLowerCase().includes(value.toLowerCase())) {
                    node._highlighted = true;
                    node._expanded = true;
                }
                return node;
            });

            chart.data(filteredData).render();

            const matchedNode = filteredData.find((node) => node._highlighted);
            if (matchedNode) {
                centerNode(matchedNode.id);
            }
        }
    };

    const centerNode = (nodeId) => {
        if (chart) {
            chart.setCentered(nodeId).render();
        } else {
            console.error("Chart is not initialized yet.");
        }
    };

    return (
        <div className="sm:w-full md:min-w-screen h-auto bg-[#101B30]">
            <div className="w-full flex flex-col lg:flex-row px-2 gap-2 lg:gap-4">
                <input
                    type="search"
                    placeholder="Search by name"
                    className="border px-2 py-2 mb-4 w-full lg:w-1/2 rounded-md"
                    value={filter}
                    onChange={(ev) => setFilter(ev.target.value)}
                />
                <Button
                    injectClass="text-white  lg:w-44 bg-nameColor ring-sColor rounded-full"
                    onClick={() => handleFilterChange(filter)}
                >
                    Search
                </Button>
                <Button
                    injectClass="text-white  lg:w-44 bg-nameColor ring-sColor rounded-full"
                    onClick={() => {
                        setFilter("");
                        handleFilterChange(userDetails.subject.refId);
                    }}
                >
                    Clear filter
                </Button>
            </div>
            <div
                className="chart-container org-chart w-full h-full"
                ref={chartRef}
            ></div>
        </div>
    );
};

export default Chart;