import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { createStore } from 'redux';
import { reducer } from './Store/reducer'
import Loaders from './Components/Loaders/Loaders';
import Root from './Root/Root';

const store = createStore(reducer)

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loaders />}>
        <Router>
          <div className="min-h-screen font flex flex-col bg-lightBg relative" style={{ fontFamily: "Graphik, Helvetica, Arial, sans-serif" }}>
            <Root />
          </div>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
