import React from "react"
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from 'yup'
import FieldInput from '../../Components/FieldInput'
import Button from '../../Components/Button'
import { setAlert } from "../../Store/Theme/actions"
import API from "../../Common/API"

const initialSupportForm = {
    message: ''
}

const Support = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state.auth.userDetails)


    const supportFormSchema = Yup.object().shape({
        message: Yup.string().required("Message is required")
    })

    const supportForm = useFormik({
        initialValues: initialSupportForm,
        validationSchema: supportFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            values.refId = userDetails.subject.refId
            values.userId = userDetails.subject._id
            values.orgCode = 'Freeminers'
            API.post('/common/support', values).then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: 'Success', subtitle: 'Your request submitted successfully. We will contact you shortly', type: 'success', active: true }));
                    supportForm.resetForm()
                }
            })
        }
    })

    return (
        <motion.div className="h-full w-full px-2 py-5 flex flex-col justify-center">
            <FieldInput type="textarea" placeholder="Type your message here..." label={'Enter message'} fieldHelper={supportForm.getFieldHelpers('message')} fieldProps={supportForm.getFieldProps('message')} fieldMeta={supportForm.getFieldMeta('message')} edit={true} dataLoaded={true} />
            <motion.div className='flex flex-row justify-center'>
                <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => supportForm.submitForm()}>Send</Button>
            </motion.div>
        </motion.div>
    )
}

export default Support