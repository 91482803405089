import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { setAlert } from '../../Store/Theme/actions';
import { FaShareAlt } from "react-icons/fa";
import {
    EmailShareButton, FacebookShareButton, WhatsappShareButton, TelegramShareButton, TwitterShareButton
} from "react-share";
import {
    FaFacebook, FaTwitter, FaWhatsapp, FaTelegram, FaEnvelope
} from "react-icons/fa"; // Import appropriate share icons

const ReferralLink = () => {
    const userDetails = useSelector((state) => state.auth.userDetails);

    const links = [
        {
            title: "Left Referral Link",
            link: `${window.location.origin}/referral-link/${userDetails.subject.refId}/left`,
        },
        {
            title: "Right Referral Link",
            link: `${window.location.origin}/referral-link/${userDetails.subject.refId}/right`,
        },
    ];

    const [isCopied, setIsCopied] = useState(false);
    const dispatch = useDispatch();

    // Store the open state of each link's share button separately
    const [openShare, setOpenShare] = useState({});

    const onCopyText = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    useEffect(() => {
        if (isCopied) {
            dispatch(setAlert({ title: 'Success', subtitle: 'Link copied to clipboard', active: true, type: 'success' }));
        }
    }, [isCopied, dispatch]);

    // Function to toggle the share options for a specific link
    const toggleShare = (link) => {
        setOpenShare((prevState) => ({
            ...prevState,
            [link]: !prevState[link] // Toggle the specific link's share state
        }));
    };

    return (
        <div className="card-container sendReferral-container w-full">
            <div className="w-full my-2">
                {links.map(({ link, title }) => (
                    <motion.div key={link} className="flex h-20 bg-white items-center rounded-xl flex-row justify-between my-5 w-full">
                        <CopyToClipboard className="flex  w-full bg-white rounded-xl flex-row justify-between py-2 px-5 my-5" text={link} onCopy={onCopyText}>
                            <p className="send-link">
                                <span>{title}</span>
                                <BiCopy className="cursor-pointer" />
                            </p>
                        </CopyToClipboard>
                        <motion.div className="flex w-12 relative flex-row items-center">
                            <FaShareAlt onClick={() => toggleShare(link)} className="cursor-pointer" />
                            {
                                openShare[link] && (
                                    <motion.div
                                        className="flex absolute drop-shadow-lg top-full border border-gray px-5 -left-40 mt-2 flex-row space-x-4 bg-white p-2 rounded-lg z-10"
                                    >
                                        <FacebookShareButton url={link}>
                                            <FaFacebook size={24} />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={link}>
                                            <FaTwitter size={24} />
                                        </TwitterShareButton>
                                        <WhatsappShareButton url={link}>
                                            <FaWhatsapp size={24} />
                                        </WhatsappShareButton>
                                        <TelegramShareButton url={link}>
                                            <FaTelegram size={24} />
                                        </TelegramShareButton>
                                        <EmailShareButton url={link}>
                                            <FaEnvelope size={24} />
                                        </EmailShareButton>
                                    </motion.div>
                                )
                            }
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default ReferralLink;