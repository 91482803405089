import React, { useEffect, useState } from "react"
import { TbMoneybag, TbRouteAltRight, TbRouteAltLeft } from "react-icons/tb";
import { motion } from 'framer-motion'
import logo from '../../Assets/Images/sitelogo.png'
import { GiTakeMyMoney } from "react-icons/gi";
import { FaArrowLeft, FaArrowRight, FaWallet } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import { GiWallet } from "react-icons/gi";
import API from "../../Common/API";
import DashboardLoader from "../../Components/Loaders/DashboardLoader";
import { useSelector } from "react-redux";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { SlSizeFullscreen } from "react-icons/sl";
import "slick-carousel/slick/slick-theme.css";
import Dialog from '../../Components/Dialog'

const Dashboard = () => {

    const [enableArrows, setEnableArrows] = useState(false)
    const array = [1, 2, 3, 4];
    const [startIndex, setStartIndex] = useState(0);
    const [direction, setDirection] = useState(0);
    const [loading, setLoading] = useState(true)
    const [dashRes, setDashRes] = useState()
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const [openMediaDialog, setOpenMediaDialog] = useState(false)
    const [walletCards, setWalletCards] = useState([{ name: 'Staking Wallet', value: 0, icon: FaWallet, color: '[#AB54DB]' },
    { name: 'Earning Wallet', value: 0, icon: GiReceiveMoney, color: 'nameColor/40' },
    { name: 'Transfer Wallet', value: 0, icon: GiWallet, color: 'nameColor/80' },
    { name: 'Offer Wallet', value: 0, icon: BiSolidOffer, color: 'teal-500' }])

    const handleRightClick = () => {
        setDirection(1)
        setStartIndex((prevIndex) => (prevIndex + 1) % walletCards.length);
    };

    const handleLeftClick = () => {
        setDirection(-1)
        setStartIndex((prevIndex) => (prevIndex - 1 + walletCards.length) % walletCards.length);
    };

    const getDisplayedNumbers = () => {
        const firstNumber = walletCards[startIndex];
        const secondNumber = walletCards[(startIndex + 1) % walletCards.length];
        return [firstNumber, secondNumber];
    };

    const [first, second] = getDisplayedNumbers();
    const settings = {
        dots: false, // Disable dots
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 5000, // Time interval (in milliseconds)
    };

    useEffect(() => {
        if (isLoggedIn) {
            API.get('/common/getDashboardDetails').then((res) => {
                if (res.status === 200) {
                    setDashRes(res.data.data)
                    setWalletCards([{ name: 'Staking Wallet', value: (res.data.data.stakingWallet).toFixed(2), icon: FaWallet, color: '[#AB54DB]' },
                    { name: 'Earning Wallet', value: (res.data.data.earningWallet).toFixed(2), icon: GiReceiveMoney, color: 'nameColor/40' },
                    { name: 'Transfer Wallet', value: (res.data.data.transferWallet).toFixed(2), icon: GiWallet, color: 'nameColor/80' },
                    { name: 'Offer Wallet', value: (res.data.data.offerWallet).toFixed(2), icon: BiSolidOffer, color: 'teal-500' }])
                    setLoading(false)
                }
            })
        }
    }, [])


    return (
        <>
            {
                loading ? <DashboardLoader /> : <motion.div className="relative h-full my-2 w-full flex flex-col">
                    <motion.div className="flex flex-col md:flex-row w-full my-5 gap-5 justify-between">
                        <motion.div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5 w-full">
                            {/* Total Staking */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Total Staking</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.totalStaking).toFixed(2)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <GiTakeMyMoney className="text-2xl text-white font-bold" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            {/* Daily Ros */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Daily Ros</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.dailyRos).toFixed(2)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <TbMoneybag className="text-2xl font-bold text-white" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            {/* Left Business */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Left Business</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.leftBusinessTotal).toFixed(2)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <TbRouteAltRight className="rotate-180 text-white text-2xl font-bold" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            {/* Right Business */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Right Business</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.rightBusinessTotal).toFixed(2)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <TbRouteAltLeft className="rotate-180 text-white text-2xl font-bold" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                        <motion.div className="bg-white py-5 h-48 md:h-52 md:py-0 px-10 w-full md:w-2/4 text-black relative rounded-2xl flex flex-col justify-center">
                            <motion.div className="flex flex-col">
                                <motion.div className="text-xl md:text-5xl font-bold">Total Wallet</motion.div>
                                <motion.div className="text-2xl my-2 px-2 font-bold">{(dashRes.totalWallet).toFixed(2)}</motion.div>
                            </motion.div>
                            <motion.div className="absolute bg-opacity-80 right-0 bottom-0">
                                <img src={logo} width={200} className="opacity-10" />
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-col xl:flex-row w-full my-5 xl:gap-5 justify-between">
                        <motion.div className="flex flex-col justify-center w-full xl:w-2/4 my-5 xl:my-0 relative" onMouseEnter={() => setEnableArrows(true)} onMouseLeave={() => setEnableArrows(false)}>
                            {enableArrows && (
                                <motion.div className="absolute xl:flex hidden cursor-pointer -left-5 drop-shadow-lg flex-col flex justify-center items-center hover:bg-nameColor hover:text-white bg-white h-12 w-12 rounded-2xl" onClick={() => handleLeftClick()}>
                                    <FaArrowLeft />
                                </motion.div>
                            )}
                            <motion.div className="grid hidden xl:flex grid-cols-1 md:grid-cols-2 gap-5">
                                <motion.div ref={first} className="w-full rounded-2xl py-5 px-2 h-60 bg-white">
                                    <motion.div className="flex flex-row justify-start">
                                        <motion.div className={`bg-black h-12 w-12 rounded-full flex flex-col justify-center items-center text-center`}>
                                            <first.icon className="text-2xl text-white" />
                                        </motion.div>
                                        <motion.div className="text-xl flex flex-col justify-center mx-2 font-bold">{first.name}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-3xl py-10 flex flex-col justify-center items-center">{first.value}</motion.div>
                                </motion.div>
                                <motion.div ref={second} className="w-full rounded-2xl py-5 px-2 h-60 bg-white">
                                    <motion.div className="flex flex-row justify-start">
                                        <motion.div className={`bg-black h-12 w-12 rounded-full flex flex-col justify-center items-center text-center`}>
                                            <second.icon className="text-2xl text-white" />
                                        </motion.div>
                                        <motion.div className="text-xl flex flex-col justify-center mx-2 font-bold">{second.name}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-3xl py-10 flex flex-col justify-center items-center">{second.value}</motion.div>
                                </motion.div>
                            </motion.div>
                            <motion.div className="grid block xl:hidden grid-cols-1 md:grid-cols-2 gap-5">
                                {walletCards && walletCards.length > 0 && walletCards.map((wal) => {
                                    return (<motion.div className="w-full rounded-2xl h-32 py-2 px-2 bg-white">
                                        <motion.div className="flex flex-row justify-start">
                                            <motion.div className={`bg-black h-12 w-12 rounded-full flex flex-col justify-center items-center text-center`}>
                                                <first.icon className="text-2xl text-white" />
                                            </motion.div>
                                            <motion.div className="text-xl flex flex-col justify-center mx-2 font-bold">{wal.name}</motion.div>
                                        </motion.div>
                                        <motion.div className="text-3xl flex flex-col justify-center items-center">{wal.value}</motion.div>
                                    </motion.div>)
                                })}
                            </motion.div>
                            {enableArrows && (
                                <motion.div className="absolute hidden xl:flex cursor-pointer -right-5 z-40 drop-shadow-lg flex-col flex justify-center items-center hover:bg-nameColor hover:text-white bg-white h-12 w-12 rounded-2xl" onClick={() => handleRightClick()}>
                                    <FaArrowRight />
                                </motion.div>
                            )}
                        </motion.div>
                        <motion.div className="bg-white px-10 w-full xl:w-2/4 text-black relative rounded-2xl flex flex-col justify-center">
                            {dashRes.medias.length && dashRes.medias.length >= 1 ? <motion.div className="cursor-pointer flex flex-row justify-end w-full items-center px-2 py-2" onClick={() => setOpenMediaDialog(true)}>
                                <SlSizeFullscreen /> &nbsp; View All
                            </motion.div> : ''}
                            {
                                dashRes.medias.length && dashRes.medias.length === 1 ? <div className="h-96 w-96">
                                    <img src={dashRes.medias[0].fileUrl} className="contain h-96 w-96" alt={'Slide1'} />
                                </div> : dashRes.medias.length > 1 ? <Slider pauseOnHover={true} {...settings} className="w-96">
                                    {
                                        dashRes.medias.map((slide, i) => {
                                            return <div className="h-32 w-32">
                                                <img src={slide.fileUrl} className="md:contain cover h-full w-full" height={100} width={100} alt={'Slide1'} />
                                            </div>
                                        })
                                    }
                                </Slider> : <motion.div className="h-full w-full flex flex-col items-center justify-center text-center">
                                    No Media
                                </motion.div>
                            }
                        </motion.div>
                    </motion.div>
                    <motion.div className="flex flex-col xl:flex-row w-full my-5 xl:gap-10 justify-between">
                        <motion.div className="bg-white px-10 w-full xl:w-2/4 text-black relative rounded-2xl flex flex-col justify-center">
                            <motion.div className="flex flex-col">
                                <motion.div className="my-2 px-2 font-bold">
                                    <motion.div className="flex my-1 flex-row">
                                        <motion.div className="h-10 w-10 rounded-xl justify-center items-center flex flex-col bg-black">
                                            <TbRouteAltRight className="rotate-180 text-2xl font-bold text-white" />
                                        </motion.div>
                                        <motion.div className="justify-center items-center flex flex-col mx-2">Left Users</motion.div>
                                    </motion.div>
                                    <div className="w-full bg-slate-200 rounded-full">
                                        <div
                                            className="bg-rose-700 text-xs font-medium text-black text-center p-0.5 leading-none rounded-full"
                                            style={{
                                                width: `${Math.min(
                                                    ((parseInt(dashRes.activeLeftCount) / parseInt(dashRes.leftCount)) * 100),
                                                    100
                                                )}%`,
                                            }}
                                        >
                                            {Math.min(
                                                ((parseInt(dashRes.activeLeftCount) / parseInt(dashRes.leftCount)) * 100),
                                                100
                                            ).toFixed(2)}%
                                        </div>
                                    </div>
                                    <div className="my-1 flex flex-row">
                                        {dashRes.leftCount} Users / {dashRes.activeLeftCount} Active
                                    </div>
                                </motion.div>
                                <motion.div className="my-2 px-2 font-bold">
                                    <motion.div className="flex my-1 flex-row">
                                        <motion.div className="h-10 w-10 rounded-xl justify-center items-center flex flex-col bg-black">
                                            <TbRouteAltLeft className="rotate-180 text-2xl font-bold text-white" />
                                        </motion.div>
                                        <motion.div className="justify-center items-center flex flex-col mx-2">Right Users</motion.div>
                                    </motion.div>
                                    <div className="w-full bg-slate-200 rounded-full">
                                        <div
                                            className="bg-rose-700 text-xs font-medium text-black text-center p-0.5 leading-none rounded-full"
                                            style={{
                                                width: `${Math.min(
                                                    ((parseInt(dashRes.activeRightCount) / parseInt(dashRes.rightCount)) * 100),
                                                    100
                                                )}%`,
                                            }}
                                        >
                                            {Math.min(
                                                ((parseInt(dashRes.activeRightCount) / parseInt(dashRes.rightCount)) * 100),
                                                100
                                            ).toFixed(2)}%
                                        </div>
                                    </div>
                                    <div className="my-1 flex flex-row">
                                        {dashRes.rightCount} Users / {dashRes.activeRightCount} Active
                                    </div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                        <motion.div className={`grid grid-cols-1 my-2 sm:grid-cols-1 w-full md:w-2/4 md:grid-cols-1 gap-5`}>
                            {/* Left Business */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Today Left Business</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.todayBonus.leftBusinessTotal || 0)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <TbRouteAltRight className="rotate-180 text-white text-2xl font-bold" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                            {/* Right Business */}
                            <motion.div className="bg-white w-full rounded-2xl flex flex-col justify-center px-5 h-24">
                                <motion.div className="flex flex-row justify-between">
                                    <motion.div className="flex flex-col">
                                        <motion.div className="text-xl font-bold text-black">Today Right Business</motion.div>
                                        <motion.div className="text-lg font-bold text-black">{(dashRes.todayBonus.rightBusinessTotal || 0)}</motion.div>
                                    </motion.div>
                                    <motion.div className="text-xl flex flex-col justify-center items-center bg-black rounded-xl h-12 w-12">
                                        <TbRouteAltLeft className="rotate-180 text-2xl text-white font-bold" />
                                    </motion.div>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                </motion.div>
            }
            <Dialog title="Medias" showDialog={openMediaDialog} onClose={() => setOpenMediaDialog(false)}>
                <motion.div className="h-full py-10 w-full">
                    {
                        dashRes?.medias.length && dashRes?.medias.length === 1 ? <div className="h-96 w-full">
                            <img src={dashRes?.medias[0].fileUrl} className="contain h-96 w-full" alt={'Slide1'} />
                        </div> : <Slider pauseOnHover={true} {...settings} className="w-full">
                            {
                                dashRes?.medias.map((slide, i) => {
                                    return <div className="h-96 w-full">
                                        <img src={slide.fileUrl} className="md:contain cover h-96 w-full" alt={'Slide1'} />
                                    </div>
                                })
                            }
                        </Slider>
                    }
                </motion.div>
            </Dialog>
        </>
    )
}

export default Dashboard