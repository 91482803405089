import { FiHome } from "react-icons/fi";
import { RiTeamFill } from "react-icons/ri";
import { GiWallet } from "react-icons/gi";
import { TbTransactionDollar, TbReportSearch } from "react-icons/tb";
import { FaCogs } from "react-icons/fa";

export const HomeMenu = [
    {
        TitleIcon: FiHome,
        title: 'Home',
        subtitle: 'Home',
        expand: false
    },
    {
        TitleIcon: RiTeamFill,
        title: 'Team',
        subtitle: 'Team',
        expand: false
    },
    {
        TitleIcon: GiWallet,
        title: 'Wallet Report',
        subtitle: 'Wallet Report',
        expand: false
    },
    {
        TitleIcon: TbTransactionDollar,
        title: 'Transactions',
        subtitle: 'Transactions',
        expand: false
    },
    {
        TitleIcon: TbReportSearch,
        title: 'Transactions Reports',
        subTitle: 'Transactions Reports',
        expand: false
    },
    {
        TitleIcon: FaCogs,
        title: 'Settings',
        subtitle: 'Settings',
        expand: false
    }
]
