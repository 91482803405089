import { motion } from 'framer-motion'
import { useFormik } from "formik"
import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from 'yup'
import FieldSelect from '../../Components/FieldSelect'
import FieldInput from '../../Components/FieldInput'
import Button from '../../Components/Button'
import API from '../../Common/API'
import loginBg from '../../Assets/Images/loginBg.svg'
import logo from '../../Assets/Images/sitelogo.png'
import { setAlert } from '../../Store/Theme/actions'

const initialUserForm = {
    refId: '',
    parentRefId: '',
    nodePosition: '',
    name: '',
    mailId: '',
    mobileNumber: '',
    gender: '',
    address: null
}

const nodes = [{ text: 'Left', value: 'left' }, { text: 'Right', value: 'right' }]
const gender = [{ text: 'Female', value: 'female' }, { text: 'Male', value: 'male' }]

const ExternalAddUser = ({ match }) => {

    const dispatch = useDispatch()
    const token = localStorage.getItem('token')

    const formData = useMemo(() => {
        return { ...initialUserForm, parentRefId: match.params.parentRefId, nodePosition: match.params.nodePosition }
    }, [])

    const userFormSchema = Yup.object().shape({
        refId: Yup.string(),
        parentRefId: Yup.string(),
        nodePosition: Yup.string().required("Node position is required"),
        name: Yup.string().required("Name is required"),
        mailId: Yup.string().required("Mail id is required"),
        mobileNumber: Yup.string().required("Mobile number is required"),
        gender: Yup.string().required("Gender is required")
    })

    const userForm = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: userFormSchema,
        onSubmit: (values) => {
            values.address = null
            values.role = "User"
            API.post('/user/createUser', values).then((apiRes) => {
                if (apiRes.status === 200) {
                    userForm.resetForm()
                    dispatch(setAlert({ title: 'Success', subtitle: 'User Registered Successfully', active: true, type: 'success' }))
                }
            })
        }
    })
    return (
        <motion.div className={`${token !== null ? "" : 'min-h-screen '} w-full flex flex-col`}>
            {
                token !== null ? <motion.div className="flex h-fit flex-col md:flex-row-reverse justify-between flex-1">
                    <motion.div className="flex items-center w-full bg-white flex-col  text-center">
                        <motion.div className="my-10 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
                            <FieldSelect label={'Team'} fieldProps={userForm.getFieldProps('nodePosition')} fieldMeta={userForm.getFieldMeta('nodePosition')} fieldHelper={userForm.getFieldHelpers('nodePosition')} options={nodes} edit={false} dataLoaded={true} />
                            <FieldInput label="Parent RefId" fieldProps={userForm.getFieldProps('parentRefId')} fieldMeta={userForm.getFieldMeta('parentRefId')} fieldHelper={userForm.getFieldHelpers('parentRefId')} edit={false} dataLoaded={true} />
                            <FieldInput label="Name" fieldProps={userForm.getFieldProps('name')} fieldMeta={userForm.getFieldMeta('name')} fieldHelper={userForm.getFieldHelpers('name')} edit={true} dataLoaded={true} />
                            <FieldInput label="Mail" fieldProps={userForm.getFieldProps('mailId')} fieldMeta={userForm.getFieldMeta('mailId')} fieldHelper={userForm.getFieldHelpers('mailId')} edit={true} dataLoaded={true} />
                            <FieldInput label="Phone number" fieldProps={userForm.getFieldProps('mobileNumber')} fieldMeta={userForm.getFieldMeta('mobileNumber')} fieldHelper={userForm.getFieldHelpers('mobileNumber')} edit={true} dataLoaded={true} />
                            <FieldSelect label="Gender" fieldProps={userForm.getFieldProps('gender')} fieldMeta={userForm.getFieldMeta('gender')} fieldHelper={userForm.getFieldHelpers('gender')} options={gender} edit={true} dataLoaded={true} />
                        </motion.div>
                        <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => userForm.submitForm()}>Register User</Button>
                    </motion.div>
                </motion.div> :
                    <motion.div className="flex flex-col md:flex-row-reverse justify-between flex-1">
                        <motion.div className="flex flex-col w-full md:w-2/4 bg-lightBg justify-center items-center">
                            <img src={loginBg} className="h-48 md:h-64 w-full max-w-md" alt="Login Background" />
                        </motion.div>
                        <motion.div className="flex items-center w-full md:w-2/4 bg-white flex-col justify-center text-center">
                            <motion.div className="flex flex-row justify-start">
                                <img src={logo} className="h-20 w-20 md:h-24 md:w-24" alt="Logo" />
                                <motion.div className="flex text-start justify-center flex-col ml-4">
                                    <p className='text-nameColor text-2xl md:text-4xl font-bold'>
                                        Freeminers
                                    </p>
                                    <p className='text-md md:text-lg font-semibold'>FREE COIN, THE FUTURE COIN</p>
                                </motion.div>
                            </motion.div>
                            <motion.div className="my-5 text-xl md:text-2xl font-bold">
                                Welcome, Register your account 👋
                            </motion.div>
                            <motion.div className="my-10 w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 lg:grid-cols-2">
                                <FieldSelect label={'Team'} fieldProps={userForm.getFieldProps('nodePosition')} fieldMeta={userForm.getFieldMeta('nodePosition')} fieldHelper={userForm.getFieldHelpers('nodePosition')} options={nodes} edit={false} dataLoaded={true} />
                                <FieldInput label="Parent RefId" fieldProps={userForm.getFieldProps('parentRefId')} fieldMeta={userForm.getFieldMeta('parentRefId')} fieldHelper={userForm.getFieldHelpers('parentRefId')} edit={false} dataLoaded={true} />
                                <FieldInput label="Name" fieldProps={userForm.getFieldProps('name')} fieldMeta={userForm.getFieldMeta('name')} fieldHelper={userForm.getFieldHelpers('name')} edit={true} dataLoaded={true} />
                                <FieldInput label="Mail" fieldProps={userForm.getFieldProps('mailId')} fieldMeta={userForm.getFieldMeta('mailId')} fieldHelper={userForm.getFieldHelpers('mailId')} edit={true} dataLoaded={true} />
                                <FieldInput label="Phone number" fieldProps={userForm.getFieldProps('mobileNumber')} fieldMeta={userForm.getFieldMeta('mobileNumber')} fieldHelper={userForm.getFieldHelpers('mobileNumber')} edit={true} dataLoaded={true} />
                                <FieldSelect label="Gender" fieldProps={userForm.getFieldProps('gender')} fieldMeta={userForm.getFieldMeta('gender')} fieldHelper={userForm.getFieldHelpers('gender')} options={gender} edit={true} dataLoaded={true} />
                            </motion.div>
                            <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => userForm.submitForm()}>Register User</Button>
                        </motion.div>
                    </motion.div>
            }
        </motion.div>
    )
}

export default ExternalAddUser