import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import FieldInput from '../../Components/FieldInput'
import FieldSelect from '../../Components/FieldSelect'
import Button from '../../Components/Button'
import API from '../../Common/API';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../Store/Theme/actions';
import Dialog from '../../Components/Dialog'
import OTPField from '../../Components/OTPField';

const initialWithdraw = {
    withDrawType: 'withdraw',
    coins: 0,
}

const types = [{ text: 'Withdraw', value: 'withdraw' }, { text: 'Internal-transfer', value: 'internalTransfer' }]

const Withdraw = () => {

    const dispatch = useDispatch()
    const [balance, setBalance] = useState()
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const coinValue = useSelector((state) => state.auth.coinValue)
    const userDetails = useSelector((state) => state.auth.userDetails)
    const [otpDialog, setOtpDialog] = useState(false)
    const [interWithDraw, setInterWithDraw] = useState(false)


    const withDrawFormSchema = Yup.object().shape({
        withDrawType: Yup.string().required("Types is required"),
        coins: !interWithDraw ? Yup.number().min(9999999, "Minimum coins is required").max(999999999, "Maximum limit exceed").required("Coins is required") : Yup.number().min(999999, "Minimum coins is required"),
    })

    useEffect(() => {
        API.get('/common/getTotalBalance').then((res) => {
            console.log(res.data.data)
            if (res.status === 200) {
                setBalance(res.data.data)
                setLoading(false)
            }
        })
    }, [count])

    const withDrawForm = useFormik({
        initialValues: initialWithdraw,
        validationSchema: withDrawFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            setOtpDialog(false)
            values.amount = (values.coins * (15 / coinValue.coinValue))
            values.coins = parseInt(values.coins)
            if ((values.coins < balance?.walletCoins)) {
                if (userDetails.subject.freeCoinAddress !== undefined && userDetails.subject.freeCoinAddress !== null) {
                    API.post('/transaction/withdraw', values).then((apiRes) => {
                        if (apiRes.data.data.status === 204) {
                            dispatch(setAlert({ title: "Error", subtitle: apiRes.data.data.message, active: true, type: 'error' }))
                        } else if (apiRes.status === 200) {
                            dispatch(setAlert({ title: "Success", subtitle: 'Withdraw request submitted successfully', active: true, type: 'success' }))
                            withDrawForm.resetForm()
                        }
                        setCount(count + 1)
                    })
                } else {
                    dispatch(setAlert({ title: "Error", subtitle: 'Update your free coin address before raising the withdraw request', active: true, type: 'error' }))
                }
            } else {
                dispatch(setAlert({ title: "Error", subtitle: 'Insufficient Funds In Your Account', active: true, type: 'error' }))
            }
        }
    })

    const requestOTP = () => {
        API.get('/common/requestOTP').then((otpRes) => {
            if (otpRes.status === 200) {
                setOtpDialog(true)
            }
        })
    }

    return (
        <motion.div className="relative gap-5 h-full w-full flex flex-col-reverse md:flex-row justify-between">
            <motion.div className="bg-white w-full md:w-3/5 rounded-2xl my-5 px-2 py-5">
                {!loading && <motion.div className="text-md my-5 mx-2 px-2 py-2 text-black">
                    Available coins (FC)) : {(balance ? balance.walletCoins : 0)}
                </motion.div>}
                <FieldSelect label={'Choose Type'} fieldProps={withDrawForm.getFieldProps('withDrawType')} fieldMeta={withDrawForm.getFieldMeta('withDrawType')} fieldHelper={withDrawForm.getFieldHelpers('withDrawType')} options={types} edit={true} dataLoaded={true} onChange={(ev) => ev === 'internalTransfer' && setInterWithDraw(!interWithDraw)} />
                <FieldInput label="Enter Coins" ml={true} length={10} fieldProps={withDrawForm.getFieldProps('coins')} fieldMeta={withDrawForm.getFieldMeta('coins')} fieldHelper={withDrawForm.getFieldHelpers('coins')} edit={true} dataLoaded={true} />
                <motion.div className='flex flex-row justify-center'>
                    <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => requestOTP()}>Withdraw</Button>
                </motion.div>
            </motion.div>
            <motion.div className="bg-white w-full md:w-2/5 rounded-2xl h-48 flex flex-col my-5 px-2 py-5">
                <motion.div className="text-md my-2 px-2 py-2 text-black">
                    Today Coins for 30$ : {coinValue.oneCoinPrice * 30}
                </motion.div>
                <motion.div className='flex flex-col text-md my-2 px-2 py-2 text-black'>
                    Today Coins for 1$ : {parseFloat(coinValue.oneCoinPrice).toFixed(3)}
                </motion.div>
            </motion.div>
            <Dialog injectClass={'w-[450px]'} showDialog={otpDialog} title="Confirm Verification" onClose={() => { setOtpDialog(false); withDrawForm.resetForm() }}>
                <OTPField onSucess={(ev) => ev && withDrawForm.submitForm()} onAbort={() => { setOtpDialog(false); withDrawForm.resetForm() }} />
            </Dialog>
        </motion.div>
    )
}

export default Withdraw