import React, { useState } from "react"
import { motion } from 'framer-motion'
import logo from '../Assets/Images/rsitelogo.png'
import { HomeMenu } from "../Common/HomeRoutes"
import { useHistory } from "react-router-dom"
import { CombinedRoutes } from "../Common/Routes"
import { setLoggedIn } from "../Store/Auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { setPageTitle } from "../Store/Theme/actions"

const SideBar = () => {

    const [hoverIndex, setHoverIndex] = useState()
    const [mainMenu, setMainMenu] = useState([])
    const [clickedIndex, setClickedIndex] = useState()
    const [homeRoutes, setHomeRoutes] = useState(HomeMenu)
    const history = useHistory()
    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state.auth.userDetails)

    const viewMainMenu = (title, ind) => {
        setHomeRoutes(homeRoutes.map((c, i) => {
            if (i === ind) {
                c.expand = !c.expand
            } else {
                c.expand = false
            }
            return c
        }))
        setMainMenu(CombinedRoutes.filter((x) => x.menu === title))
        setClickedIndex(ind)
    }

    return (
        <motion.div className={`px-5 md:w-80 py-10 relative md:rounded-3xl bg-nameColor h-[90vh] overflow-auto flex flex-col`}>
            <motion.div className="flex flex-row justify-start">
                <img src={logo} height={50} width={50} alt="logo" />
                <motion.div className="flex text-start justify-center flex-col">
                    <p className='justify-center text-headingColor text-lg font-bold flex flex-col'>
                        Freeminers
                    </p>
                    <p className='text-xs font-semibold'>FREE COIN, THE FUTURE COIN</p>
                </motion.div>
            </motion.div>
            <motion.div className="bg-black text-white my-5 rounded-xl">
                <div className="flex text-2xl font-bold flex-col px-10 py-2  justify-center items-center text-center">
                    <p>{userDetails?.subject?.name}</p>
                    <p>ID &nbsp;{userDetails?.subject?.refId}</p>
                </div>
            </motion.div>
            <motion.div className={`bg-black h-1 w-full border border-black flex flex-row justify-center`} />
            <motion.div className={`flex flex-col my-5`}>
                {
                    homeRoutes.map((route, index) => {
                        return <motion.div className={`flex flex-col py-2`}>
                            <motion.div className={`flex flex-row px-2 hover:text-black ${route.expand ? 'bg-white text-black' : ''} text-lg font-bold py-2 cursor-pointer hover:bg-white transition duration-300 `} onClick={() => viewMainMenu(route.title, index)} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)} >
                                <route.TitleIcon className={`my-1 mx-2  transition duration-300 `} /> <motion.div className={`mx-2`}>{route.title}</motion.div>
                            </motion.div>
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: route.expand ? 'auto' : 0, opacity: route.expand ? 1 : 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                {
                                    clickedIndex === index && route.expand && <motion.div className={`bg-white transition duration-500 h-auto drop-shadow-lg px-10  text-black flex flex-col`}>
                                        {
                                            mainMenu.map((menu) => {
                                                return menu.sidebar && <motion.div className={`flex ${history.location.pathname === menu.link && 'text-black font-bold'} flex-row py-2 px-2 cursor-pointer hover:text-black`} onClick={() => { dispatch(setPageTitle(menu.title)); history.push(menu.link) }}>
                                                    {menu.title}
                                                </motion.div>
                                            })
                                        }
                                    </motion.div>
                                }
                            </motion.div>
                        </motion.div>
                    })
                }
            </motion.div>
        </motion.div>
    )
}

export default SideBar