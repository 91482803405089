import React, { useEffect, useMemo, useState } from "react"
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from 'yup'
import FieldSelect from "../../Components/FieldSelect"
import FieldInput from "../../Components/FieldInput"
import Button from "../../Components/Button"
import { setAlert } from "../../Store/Theme/actions"
import { setUserDetails } from '../../Store/Auth/actions'
import API from '../../Common/API'
import ProfileLoader from "../../Components/Loaders/ProfileLoader"
import Dialog from "../../Components/Dialog"
import OTPField from "../../Components/OTPField"

const initialProfileValues = {
    name: '',
    gender: '',
    mailId: '',
    mobileNumber: '',
    joinedOn: '',
    dateOfBirth: '',
    nominee: '',
    freeCoinAddress: '',
    relation: '',
    address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pinCode: ''
    }
}

const genders = [{ text: 'Male', value: 'Male' }, { text: 'Female', value: 'Female' }, { text: 'Other', value: 'Other' }]

const Profile = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state.auth.userDetails)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [editAdd, setEditAdd] = useState(true)
    const [otpDialog, setOtpDialog] = useState(false)

    useEffect(() => {
        API.get(`/user/getUserDetails/${userDetails.subject.refId}`).then((apiRes) => {
            if (apiRes.status === 200) {
                dispatch(setUserDetails({ subject: apiRes.data.data }))
                setLoading(false)
            }
        })
    }, [count])

    const formData = useMemo(() => {
        return { ...initialProfileValues, ...userDetails.subject }
    }, [userDetails])


    const profileFormSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        gender: Yup.string().required("Gender is required"),
        mailId: Yup.string().required("Email is required"),
        freeCoinAddress: Yup.string().nullable(),
        mobileNumber: Yup.string().required("Mobile is required"),
        joinedOn: Yup.string().nullable(),
        dateOfBirth: Yup.string().nullable(),
        nominee: Yup.string().nullable(),
        relation: Yup.string().nullable(),
        address: Yup.object().nullable().shape({
            addressLine1: Yup.string().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            country: Yup.string().nullable(),
            pinCode: Yup.string().nullable()
        }),
    })

    const profileForm = useFormik({
        initialValues: formData,
        validateOnMount: true,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setOtpDialog(false)
            API.post('/user/updateUser', values).then((updateRes) => {
                if (updateRes.status === 200) {
                    dispatch(setUserDetails({ subject: updateRes.data.data }))
                    dispatch(setAlert({ title: 'Success', subtitle: 'Profile Updated Successfully' }))
                    setCount(count + 1)
                    setLoading(true)
                }
            })
        }
    })

    // profileForm.submitForm

    const requestOTP = () => {
        API.get('/common/requestOTP').then((otpRes) => {
            setOtpDialog(true)
        })
    }

    return (
        <>
            {
                loading ? <ProfileLoader /> : <motion.div className="relative w-full h-full flex flex-col">
                    <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                        <motion.div className="px-5 my-2 font-bold">Basic Information</motion.div>
                        <motion.div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
                            <FieldInput disabled={true} label={'Name'} placeholder="Enter your name" fieldHelper={profileForm.getFieldHelpers('name')} fieldMeta={profileForm.getFieldMeta('name')} fieldProps={profileForm.getFieldProps('name')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'Gender'} placeholder="Enter your Gender" fieldHelper={profileForm.getFieldHelpers('gender')} fieldMeta={profileForm.getFieldMeta('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={true} options={genders} dataLoaded={true} />
                            <FieldInput disabled={true} label={'Mobile Number'} placeholder="Enter your mobile number" fieldHelper={profileForm.getFieldHelpers('mobileNumber')} fieldMeta={profileForm.getFieldMeta('mobileNumber')} fieldProps={profileForm.getFieldProps('mobileNumber')} edit={true} dataLoaded={true} />
                            <FieldInput disabled={true} label={'Email'} placeholder="Enter your email" fieldHelper={profileForm.getFieldHelpers('mailId')} fieldMeta={profileForm.getFieldMeta('mailId')} fieldProps={profileForm.getFieldProps('mailId')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Date Of Birth'} type="date" placeholder="Enter your name" fieldHelper={profileForm.getFieldHelpers('dob')} fieldMeta={profileForm.getFieldMeta('dob')} fieldProps={profileForm.getFieldProps('dob')} edit={true} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                        <motion.div className="px-5 my-2 font-bold">Nominee Information & Coin Address</motion.div>
                        <motion.div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                            <FieldInput label={'Nominee Name'} placeholder="Enter your nominee name" fieldHelper={profileForm.getFieldHelpers('nomineeName')} fieldMeta={profileForm.getFieldMeta('nomineeName')} fieldProps={profileForm.getFieldProps('nomineeName')} edit={true} dataLoaded={true} />
                            <FieldSelect label={'Relation'} placeholder="Enter your Relation" fieldHelper={profileForm.getFieldHelpers('relation')} fieldMeta={profileForm.getFieldMeta('relation')} fieldProps={profileForm.getFieldProps('relation')} edit={true} options={genders} dataLoaded={true} />
                            <FieldInput label={'Free Coin Address'} placeholder="Enter your coin address" fieldHelper={profileForm.getFieldHelpers('freeCoinAddress')} fieldMeta={profileForm.getFieldMeta('freeCoinAddress')} fieldProps={profileForm.getFieldProps('freeCoinAddress')} edit={!profileForm.values.freeCoinAddress} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    <motion.div className="bg-slate-200 my-2 py-2 px-2 rounded-3xl  flex flex-col">
                        <motion.div className="px-5 my-2 font-bold">Address Information</motion.div>
                        <motion.div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                            <FieldInput label={'Address Line1'} placeholder="Enter your address line 1" fieldHelper={profileForm.getFieldHelpers('address.addressLine1')} fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Address Line2'} placeholder="Enter your address line 2" fieldHelper={profileForm.getFieldHelpers('address.addressLine2')} fieldMeta={profileForm.getFieldMeta('address.addressLine2')} fieldProps={profileForm.getFieldProps('address.addressLine2')} edit={true} dataLoaded={true} />
                            <FieldInput label={'City'} placeholder="Enter your city" fieldHelper={profileForm.getFieldHelpers('address.city')} fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} edit={true} dataLoaded={true} />
                            <FieldInput label={'State'} placeholder="Enter your state" fieldHelper={profileForm.getFieldHelpers('address.state')} fieldMeta={profileForm.getFieldMeta('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Country'} placeholder="Enter your country" fieldHelper={profileForm.getFieldHelpers('address.country')} fieldMeta={profileForm.getFieldMeta('address.country')} fieldProps={profileForm.getFieldProps('address.country')} edit={true} dataLoaded={true} />
                            <FieldInput label={'Pincode'} placeholder="Enter your pincode" fieldHelper={profileForm.getFieldHelpers('address.pinCode')} fieldMeta={profileForm.getFieldMeta('address.pinCode')} fieldProps={profileForm.getFieldProps('address.pinCode')} edit={true} dataLoaded={true} />
                        </motion.div>
                    </motion.div>
                    <motion.div className='flex flex-row justify-center'>
                        <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => requestOTP()}>Update</Button>
                    </motion.div>
                    <Dialog title={'Verify Confirmation'} showDialog={otpDialog} onClose={() => { setOtpDialog(false) }}>
                        <OTPField onSucess={(ev) => { ev && profileForm.submitForm() }} onAbort={() => setOtpDialog(false)} />
                    </Dialog>
                </motion.div>
            }
        </>
    )
}

export default Profile