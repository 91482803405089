import React from "react"
import { motion } from 'framer-motion'

const FieldInputLoader = () => {
    return (
        <motion.div className={`relative mt-2 mb-2 p-5`}>
            <motion.div className={`absolute -top-3 left-2 drop-shadow-sm  w-28 h-3 bg-slate-700 rounded-full animate-pulse px-5`}></motion.div>
            <motion.div className={`relative  drop-shadow-lg ring-[lightgrey] rounded h-11 px-2 py-2 w-full flex flex-col`}>
                <motion.div className={`h-5 rounded-full w-full flex flex-row bg-slate-700 animate-pulse`}></motion.div>
            </motion.div>
        </motion.div>
    )
}

export default FieldInputLoader