import { motion } from 'framer-motion'
import { IoIosAlert } from "react-icons/io";
import { useEffect, useRef, useMemo, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useField } from 'formik';
import { PatternFormat } from 'react-number-format'

const FieldInput = ({ type = "text", label, fieldProps, injectClass, disabled = false, fieldHelper, fieldMeta, onChange, autoComplete = "true", placeholder, edit, dataLoaded = false, ml, length, labelInjectClass, minDate, maxDate, instruction = null, onEnter, outerInjectClass }) => {
    const input = useRef(null)
    const wrapRef = useRef(null)
    const [showError, setShowError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const handleFocus = () => {
        if (input.current !== null && type !== 'phone') {
            input.current.focus()
        }
    }

    const inputType = useMemo(() => {
        if (type === "password") return showPassword ? "text" : "password"
        return type
    }, [type, showPassword])

    const inputChange = (e) => {
        if (type === 'file' && typeof onChange === 'function') {
            const file = e.target.files[0];
            // fieldHelper.setValue(file); // Store the file in the form
            // fieldHelper.setTouched(true);
            // if (fieldHelper && typeof fieldHelper.setValue === 'function') {
            // }
            setTimeout(() => {
                onChange(file); // Pass the file to the parent handler
            }, 100);
        } else if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    };

    const toggleError = (val) => setShowError(val)

    useEffect(() => {
        if (type === 'file' && fieldMeta && fieldMeta.touched === false) {
            if (input.current) {
                input.current.value = ""; // Clear the file input
            }
            fieldHelper.setValue(null); // Reset the form value
        }
    }, [fieldMeta.touched, fieldProps.value]);

    const enterFunction = (event) => {
        if (event.key === 'Enter') {
            onEnter(event.target.value)
        }
    }

    return (
        <motion.div className={`relative mt-2 mb-2 p-5 ${outerInjectClass}`}>
            <motion.div className={`absolute -top-3 left-2 drop-shadow-sm rounded w-auto text-tPColor ${labelInjectClass} px-5`}>
                {label}
            </motion.div>
            <motion.div className={`relative ${edit && 'ring-1'} ${injectClass} drop-shadow-lg ring-[lightgrey] rounded ${type === 'textarea' ? 'h-auto' : 'h-11 '} px-2 py-2 w-full flex flex-col ${disabled ? 'bg-[lightGrey]' : ''}`} onClick={handleFocus}>
                <motion.div ref={wrapRef} className={`${type === 'textarea' ? 'h-auto' : 'h-9'} w-full flex flex-row`}>
                    {type === 'phone' ? <PatternFormat disabled={disabled} className={`pb-1.5 pl-3 bg-transparent pr-10 w-full outline-none`} ref={input} {...fieldProps} displayType={'input'} format="##########" /> :
                        type === 'date' ? <input type={'date'} ref={input} className={`w-full px-2 focus:outline-none bg-transparent ${injectClass}`}  {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={disabled} min={minDate} max={maxDate} onInput={inputChange} />
                            : type === 'textarea' ? <textarea rows={5} ref={input} className={`w-full px-2 focus:outline-none bg-transparent ${injectClass}`} maxLength={ml && length} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={!edit || disabled} onKeyUp={inputChange} onKeyDown={(ev) => typeof onEnter === 'function' && enterFunction(ev)} ></textarea> :
                                type === 'file' ? <input
                                    type={inputType}
                                    ref={input}
                                    className={`w-full px-2 focus:outline-none bg-transparent ${injectClass}`}
                                    maxLength={ml && length}
                                    {...fieldProps}
                                    autoComplete={autoComplete}
                                    placeholder={placeholder}
                                    disabled={!edit || disabled}
                                    onChange={inputChange}
                                /> :
                                    <input type={inputType} ref={input} className={`w-full px-2 focus:outline-none bg-transparent ${injectClass}`} maxLength={ml && length} {...fieldProps} autoComplete={autoComplete} placeholder={placeholder} disabled={!edit || disabled} onKeyUp={inputChange} onKeyDown={(ev) => typeof onEnter === 'function' && enterFunction(ev)} />}
                    {type === 'password' && !showPassword && <FaEyeSlash className='my-1.5' onClick={() => setShowPassword(true)} />}
                    {type === 'password' && showPassword && <FaEye className='my-1.5' onClick={() => setShowPassword(false)} />}
                    {showError && <motion.div className={'absolute flex top-0 right-8 w-auto px-5 rounded py-2 h-7 text-center align-center items-center bg-pColor text-[red]'}>{fieldMeta.error}</motion.div>}
                    {edit && fieldMeta.error && fieldMeta.touched && <IoIosAlert className='w-8 cursor-pointer text-[red]' onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                </motion.div>
            </motion.div>
            {instruction && <p className='text-xs font-bold py-1'>{instruction}</p>}
        </motion.div>
    )
}

export default FieldInput