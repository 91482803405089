import React from 'react'
import { motion } from 'framer-motion'
import freeminersPdf from '../../Assets/businessPlan.pdf'

const Plan = () => {
    return (
        <motion.div className="h-full w-full flex flex-col relative">
            <object
                className="pdf md:block hidden w-full"
                data={freeminersPdf}
                style={{ height: 'calc(100vh - 100px)' }}
                type="application/pdf"
            >
            </object>
            <iframe src={freeminersPdf} height={600} width={100} className='md:hidden block w-full h-full' />
        </motion.div>
    );
}
export default Plan