import React, { useEffect, useState } from "react"
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from "react-redux"
import dummyProfile from '../Assets/Images/dummyProfile.jpeg'
import { FaPowerOff } from "react-icons/fa";
import { setLoggedIn } from "../Store/Auth/actions";
import { useHistory } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import SideBar from "./SideBar";

const TopBar = () => {

    const userDetails = useSelector((state) => state.auth.userDetails)
    const dispatch = useDispatch()
    const history = useHistory()
    const coinValue = useSelector((state) => state.auth.coinValue)

    const logout = () => {
        localStorage.removeItem('token')
        dispatch(setLoggedIn(false))
        history.push('/')
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    useEffect(() => {
        if (isSidebarOpen) {
            setIsSidebarOpen(false)
        }
    }, [window.location.href])

    const getGreeting = () => {
        const now = new Date();
        const hours = now.getHours();

        if (hours < 12) {
            return "Good morning!";
        } else if (hours < 18) {
            return "Good afternoon!";
        } else {
            return "Good evening!";
        }
    }

    return (
        <>
            <motion.div className="flex flex-row justify-between bg-nameColor items-center px-2 md:px-5 md:py-2 relative md:rounded-xl w-full h-14">
                <motion.div className="flex flex-row w-64 justify-between">
                    <motion.div className="flex flex-row justify-start text-[15px] font-bold">
                        {getGreeting()}
                    </motion.div>
                </motion.div>
                <motion.div className="flex flex-row justify-center text-[15px] text-center items-center justify-center w-full">
                    <motion.div>Today Coins for 30$ : <br /><span className="font-bold">{coinValue ? coinValue.oneCoinPrice * 30 : 0}</span></motion.div>
                </motion.div>
                <motion.div className="flex hidden md:inline-flex items-center flex-row justify-between w-64">
                    <FaPowerOff className={`hover:text-nameColor hidden text-xl font-bold cursor-pointer text-black rounded-xl transition duration-300`} onClick={() => logout()} />
                </motion.div>
                <motion.div className="flex flex-row w-52 justify-end">
                    {!isSidebarOpen && <motion.div className="flex flex-row justify-between"><FaPowerOff className={`hover:text-black text-[18px] mx-2 font-bold cursor-pointer text-black rounded-xl transition duration-300`} onClick={() => logout()} /><FaBars className={`hover:text-black md:hidden block text-[18px] font-bold cursor-pointer text-black transition duration-300`} onClick={toggleSidebar} /></motion.div>}
                    {isSidebarOpen && <motion.div className="flex flex-row justify-between"><FaPowerOff className={`hover:text-black text-[18px] mx-2 font-bold cursor-pointer text-black rounded-xl transition duration-300`} onClick={() => logout()} /><IoMdClose className={`hover:text-black md:hidden block text-[18px] font-bold cursor-pointer text-black transition duration-300`} onClick={toggleSidebar} /></motion.div>}
                </motion.div>
            </motion.div>
            {isSidebarOpen && <motion.div className="h-full w-full bg-white">
                <SideBar />
            </motion.div>}
        </>
    )
}

export default TopBar