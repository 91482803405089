import { motion } from 'framer-motion'
import { useFormik } from "formik"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from 'yup'
import FieldSelect from '../../Components/FieldSelect'
import FieldInput from '../../Components/FieldInput'
import Button from '../../Components/Button'
import API from '../../Common/API'
import { setAlert } from '../../Store/Theme/actions'

const initialUserForm = {
    refId: '',
    parentRefId: '',
    nodePosition: '',
    name: '',
    mailId: '',
    mobileNumber: '',
    gender: '',
    address: null
}

const nodes = [{ text: 'Left', value: 'left' }, { text: 'Right', value: 'right' }]
const gender = [{ text: 'Female', value: 'female' }, { text: 'Male', value: 'male' }]

const AddUser = () => {

    const dispatch = useDispatch()
    const userDetails = useSelector((state) => state.auth.userDetails)
    const [disableButton, setDisableButton] = useState(false)

    const formData = useMemo(() => {
        return { ...initialUserForm, parentRefId: userDetails.subject.refId }
    }, [])

    const userFormSchema = Yup.object().shape({
        refId: Yup.string(),
        parentRefId: Yup.string(),
        nodePosition: Yup.string().required("Node position is required"),
        name: Yup.string().required("Name is required"),
        mailId: Yup.string().required("Mail id is required"),
        mobileNumber: Yup.string().required("Mobile number is required"),
        gender: Yup.string().required("Gender is required")
    })

    const userForm = useFormik({
        initialValues: formData,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: userFormSchema,
        onSubmit: (values) => {
            setDisableButton(true)
            values.address = null
            values.role = "User"
            API.post('/user/createUser', values).then((apiRes) => {
                if (apiRes.status === 200) {
                    userForm.resetForm()
                    dispatch(setAlert({ title: 'Success', subtitle: 'User Registered Successfully', active: true, type: 'success' }))
                    setDisableButton(false)
                } else if (apiRes.status === 204) {
                    console.log(apiRes)
                    dispatch(setAlert({ title: 'Error', subtitle: 'Email id already exists', active: true, type: 'error' }))
                    setDisableButton(false)
                }
            })
        }
    })

    return (
        <motion.div className="flex flex-col h-full w-full px-2 py-2">
            <motion.div className="bg-slate-100 px-2 py-5 rounded grid grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3">
                <FieldSelect label={'Team'} fieldProps={userForm.getFieldProps('nodePosition')} fieldMeta={userForm.getFieldMeta('nodePosition')} fieldHelper={userForm.getFieldHelpers('nodePosition')} options={nodes} edit={true} dataLoaded={true} />
                <FieldInput label="Parent RefId" fieldProps={userForm.getFieldProps('parentRefId')} fieldMeta={userForm.getFieldMeta('parentRefId')} fieldHelper={userForm.getFieldHelpers('parentRefId')} edit={false} dataLoaded={true} />
                <FieldInput label="Name" fieldProps={userForm.getFieldProps('name')} fieldMeta={userForm.getFieldMeta('name')} fieldHelper={userForm.getFieldHelpers('name')} edit={true} dataLoaded={true} />
                <FieldInput label="Mail" fieldProps={userForm.getFieldProps('mailId')} fieldMeta={userForm.getFieldMeta('mailId')} fieldHelper={userForm.getFieldHelpers('mailId')} edit={true} dataLoaded={true} />
                <FieldInput label="Phone number" fieldProps={userForm.getFieldProps('mobileNumber')} fieldMeta={userForm.getFieldMeta('mobileNumber')} fieldHelper={userForm.getFieldHelpers('mobileNumber')} edit={true} dataLoaded={true} />
                <FieldSelect label="Gender" fieldProps={userForm.getFieldProps('gender')} fieldMeta={userForm.getFieldMeta('gender')} fieldHelper={userForm.getFieldHelpers('gender')} options={gender} edit={true} dataLoaded={true} />
            </motion.div>
            <motion.div className="flex flex-row justify-center">
                <Button outerWidth={'w-96'} injectClass={`${disableButton ? 'bg-nameColor rounded-full cursor-pointer' : 'bg-nameColor/20 rounded-full cursor-pointer'}`} onClick={() => { !disableButton && userForm.submitForm() }}>Register User</Button>
            </motion.div>
        </motion.div>
    )
}

export default AddUser