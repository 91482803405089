import React from "react"
import { motion } from 'framer-motion'


const NoticeBoard = () => {


    return (
        <motion.div className="relative h-screen bg-white  w-full rounded-xl flex flex-col justify-center items-center text-black">
            Company notices or announcements goes here
        </motion.div>
    )
}

export default NoticeBoard