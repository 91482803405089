import React, { useState, useEffect, useMemo } from "react"
import { motion } from 'framer-motion'
import { useDispatch } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import API from "../../Common/API"
import InlineTextField from "../../Components/InLineTextField"
import DataTable, { createTheme } from "react-data-table-component"
import { datatableTheme } from "../../Common/dataTableTheme"
import DataTableLoader from '../../Components/Loaders/DataTableLoader'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const OfferWalletReport = () => {

    const dispatch = useDispatch()
    const [reports, setReports] = useState([])
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    useEffect(() => {
        API.get('/reports/walletReport/offer').then((res) => {
            if (res.status === 200) {
                setReports(res.data.data)
                setLoading(false)
            }
        })
    }, [])


    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            // { name: 'RefId', selector: row => row.refId, initialActive: true, sortable: true },
            // { name: 'Parent RefId', selector: row => row.parentRefId, sortable: true },
            { name: 'Amount', selector: row => row.walletBalance, sortable: true },
            { name: 'Coins', selector: row => row.walletCoins, sortable: true },
            { name: "Message", selector: row => row.message ? row.message : '-', sortable: true, width: '500px', },
            { name: 'Date', selector: row => row.createdAt.toString().slice(0, 10), sortable: true }
        ]
    })

    const filteredReportDetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return reports.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [reports, filter])/// eslint-disable-line

    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line

    return (
        <>
            {
                loading ? <DataTableLoader /> : <DataTable title="Offer Wallet Report" theme="defaultLight" columns={columns} data={filteredReportDetails}
                    pagination noHeader highlightOnHover persistTableHead subHeader
                    subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
            }
        </>
    )
}

export default OfferWalletReport