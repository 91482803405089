import React, { useState, useEffect, useMemo } from "react"
import { motion } from 'framer-motion'
import { useDispatch } from "react-redux"
import { setAlert } from "../../Store/Theme/actions"
import API from "../../Common/API"
import InlineTextField from "../../Components/InLineTextField"
import DataTable, { createTheme } from "react-data-table-component"
import { datatableTheme } from "../../Common/dataTableTheme"
import DatatableLoader from "../../Components/Loaders/DataTableLoader"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const DirectReferrals = () => {

    const dispatch = useDispatch()
    const [referrals, setReferrals] = useState([])
    const [filter, setFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)

    useEffect(() => {
        try {
            API.get('/reports/direct-referral').then((apiRes) => {
                if (apiRes.status === 200) {
                    setReferrals(apiRes.data.data)
                    setLoading(false)
                }
            })
        } catch (err) {
            dispatch(setAlert({ title: "Error", subtitle: 'Error fetching the records', active: true, type: 'error' }))
        }
    }, [])

    const columns = useMemo(() => {
        return [
            { name: 'S.No', selector: (row, i) => { return i + 1 }, initialActive: true, sortable: true },
            { name: 'Name', selector: row => row.name, initialActive: true, sortable: true },
            { name: 'RefId', selector: row => row.refId, sortable: true },
            { name: "Staking Dollar", selector: row => row.balance?.balance?.toFixed(2), sortable: true },
            { name: 'Mobile Number', selector: row => row.mobileNumber, sortable: true },
            { name: 'Status', selector: row => { return row.activeStat === 'A' && row.balance?.balance > 0 ? <motion.div className="bg-[green] text-white  flex flex-row justify-center px-2 py-1 rounded-full w-20">Active</motion.div> : <motion.div className="bg-[red] text-white px-2 py-1 rounded-full w-20 flex flex-row justify-center">Deactivate</motion.div> }, sortable: true },
        ]
    })

    const filteredReportDetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return referrals.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [referrals, filter])/// eslint-disable-line


    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={"Search"} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line


    return (
        <>
            {
                loading ? <DatatableLoader /> : <DataTable title="User List" theme="defaultLight" columns={columns} data={filteredReportDetails}
                    pagination noHeader highlightOnHover persistTableHead subHeader
                    subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} noDataComponent={'There are no records to display'} />
            }
        </>
    )
}

export default DirectReferrals