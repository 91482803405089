import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import FieldInput from '../../Components/FieldInput'
import FieldSelect from '../../Components/FieldSelect'
import Button from '../../Components/Button'
import API from '../../Common/API';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../Store/Theme/actions';
import Dialog from '../../Components/Dialog';
import OTPField from '../../Components/OTPField';

const initialTransfer = {
    transferType: 'transfer',
    transferCoins: 0,
    toWallet: '',
    amount: 0,
}

const types = [{ text: 'Transfer', value: 'transfer' },
{ text: 'Staking', value: 'staking' }
]
const amounts = [{ text: '$30', value: 30 }, { text: '$60', value: 60 }, { text: '$90', value: 90 }, { text: '$120', value: 120 }, { text: '$150', value: 150 }, { text: '$180', value: 180 }, { text: '$500', value: 500 }, { text: '$1000', value: 1000 }, { text: 'Other', value: 'Other' }]

const TransferWallet = () => {

    const dispatch = useDispatch()
    const [balance, setBalance] = useState()
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(true)
    const [toAddress, setToAddress] = useState()
    const coinValue = useSelector((state) => state.auth.coinValue)
    const [otpDialog, setOtpDialog] = useState(false)
    const [disableButton, setDisableButton] = useState(false)

    const transferFormSchema = Yup.object().shape({
        transferType: Yup.string().required("Types is required"),
        toWallet: Yup.string().nullable(),
        amount: Yup.number().nullable(),
        transferCoins: Yup.number().nullable(),
    })

    useEffect(() => {
        API.get('/common/getTransferWalletBalance').then((res) => {
            if (res.status === 200) {
                setBalance(res.data.data)
                setLoading(false)
            }
        })
    }, [count])

    const transferForm = useFormik({
        initialValues: initialTransfer,
        validationSchema: transferFormSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            setOtpDialog(false)
            values.amount = values.transferType === 'transfer' ? (values.transferCoins * (balance.walletBalance / balance.walletCoins)) : values.amount
            if (values.amount < balance.walletBalance) {
                dispatch(setAlert({ title: "Error", subtitle: 'Insufficient Funds In Your Account', active: true, type: 'error' }))
            } else if (!(values.coins > balance.totalCoins)) {
                API.post('/transaction/transfer-wallet', values).then((apiRes) => {
                    if (apiRes.status === 200) {
                        setCount(count + 1)
                        setToAddress(null)
                        transferForm.resetForm()
                        setDisableButton(false)
                    }
                })
            } else {
                dispatch(setAlert({ title: "Error", subtitle: 'Insufficient Funds In Your Account', active: true, type: 'error' }))
            }
        }
    })

    const getBeneficieryDetails = () => {
        API.get(`/user/getUserDetails/${transferForm.values.toWallet}`).then((userRes) => {
            if (userRes.status === 200) {
                setToAddress(userRes.data.data)
            }
        })
    }

    const setAmount = (value) => {
        transferForm.setValues({ ...transferForm.values, amount: value })
    }


    const requestOTP = () => {
        setDisableButton(true)
        if (balance !== null && balance.walletCoins > 0) {
            API.get('/common/requestOTP').then((otpRes) => {
                if (otpRes.status === 200) {
                    setOtpDialog(true)
                }
            })
        } else {
            dispatch(setAlert({ title: "Error", subtitle: 'Insufficient Funds In Your Account', active: true, type: 'error' }))
        }
    }

    return (
        <motion.div className="relative gap-5 h-full w-full flex flex-col-reverse md:flex-row justify-between">
            <motion.div className="bg-white w-full md:w-3/5 rounded-2xl my-5 px-2 py-5">
                {!loading && <motion.div className="text-md my-5 mx-2 px-2 py-2 text-black">
                    Available coins (FC) : {balance != null && balance !== undefined ? (balance.walletCoins) : 0}
                </motion.div>}
                <FieldSelect label={'Choose Type'} fieldProps={transferForm.getFieldProps('transferType')} fieldMeta={transferForm.getFieldMeta('transferType')} fieldHelper={transferForm.getFieldHelpers('transferType')} options={types} edit={true} dataLoaded={true} />
                {transferForm.values.transferType === 'staking' && <motion.div className="flex flex-col">
                    <motion.div className="text-md px-5">Select Amount</motion.div>
                    <motion.div className="flex flex-row flex-wrap px-5">
                        {
                            amounts.map((amo, index) => {
                                return (
                                    <motion.div className={`px-4 mx-2 my-2 cursor-pointer ${transferForm.values.amount === amo.value ? 'bg-nameColor/20 text-nameColor' : 'bg-slate-200'} hover:text-nameColor hover:bg-nameColor/20 rounded-full py-1`} onClick={() => setAmount(amo.value)}>
                                        {amo.text}
                                    </motion.div>
                                )
                            })
                        }
                    </motion.div>
                </motion.div>}
                {transferForm.values.transferType === 'transfer' && <motion.div className="flex flex-col">
                    <FieldInput label="Enter Coins" fieldProps={transferForm.getFieldProps('transferCoins')} fieldHelper={transferForm.getFieldHelpers('transferCoins')} fieldMeta={transferForm.getFieldMeta('transferCoins')} edit={true} dataLoaded={true} />
                    <FieldInput placeholder={"FM0000000"} label="Enter Beneficiery ID" fieldProps={transferForm.getFieldProps('toWallet')} fieldHelper={transferForm.getFieldHelpers('toWallet')} fieldMeta={transferForm.getFieldMeta('toWallet')} edit={true} dataLoaded={true} onEnter={() => getBeneficieryDetails()} />
                    <motion.div className="px-5 py-0">Press enter to get the beneficiery details</motion.div>
                </motion.div>}
                <motion.div className='flex flex-row justify-center'>
                    <Button outerWidth={'w-96'} injectClass={'bg-nameColor rounded-full cursor-pointer'} onClick={() => { !disableButton && requestOTP() }}>{transferForm.values.transferType === 'transfer' ? 'Transfer' : 'Topup'}</Button>
                </motion.div>
            </motion.div>
            <motion.div className="bg-white w-full md:w-2/5 rounded-2xl flex flex-col my-5 px-2 py-5">
                <motion.div className="text-md my-2 px-2 py-2 text-black">
                    Today Coins for 30$ : {coinValue.oneCoinPrice * 30}
                </motion.div>
                <motion.div className='flex flex-col text-md my-2 px-2 py-2 text-black'>
                    Today Coins for 1$ : {parseFloat(coinValue.oneCoinPrice).toFixed(3)}
                </motion.div>
                {toAddress && <motion.div className="flex flex-col px-5 text-xl font-bold">
                    <motion.div className="flex flex-row underline justify-center items-center">
                        Beneficiery Details
                    </motion.div>
                    <motion.div className="flex flex-row w-72 justify-between">
                        <p>Name  </p>&nbsp; <p className='text-nameColor'>{toAddress.name}</p>
                    </motion.div>
                    <motion.div className="flex flex-row w-72 justify-between">
                        <p>RefId  </p>&nbsp; <p className='text-nameColor'>{toAddress.refId}</p>
                    </motion.div>
                    <motion.div className="flex flex-row w-72 justify-between">
                        <p>Mobile  </p>&nbsp; <p className='text-nameColor'>{toAddress.mobileNumber}</p>
                    </motion.div>
                </motion.div>}
            </motion.div>
            <Dialog showDialog={otpDialog} title="Confirm Verification" onClose={() => { setDisableButton(false); transferForm.resetForm(); setOtpDialog(false) }}>
                <OTPField onSucess={(ev) => ev && transferForm.submitForm()} onAbort={() => { setDisableButton(false); transferForm.resetForm(); setOtpDialog(false) }} />
            </Dialog>
        </motion.div>
    )
}

export default TransferWallet