import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import FieldInput from '../../Components/FieldInput'
import FieldSelect from '../../Components/FieldSelect'
import Button from '../../Components/Button'
import qrCode from '../../Assets/Images/qr.jpeg'
import API from '../../Common/API';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../Store/Theme/actions';
import Dialog from '../../Components/Dialog';
import OTPField from '../../Components/OTPField';

const initialTopup = {
    amount: 0,
    otherAmount: 0,
    proof: null
}

const amounts = [{ text: '$30', value: 30 }, { text: '$60', value: 60 }, { text: '$90', value: 90 }, { text: '$120', value: 120 }, { text: '$150', value: 150 }, { text: '$180', value: 180 }, { text: '$500', value: 500 }, { text: '$1000', value: 1000 }, { text: 'Other', value: 'Other' }]

const Staking = () => {

    const [otherAmount, setOtherAmount] = useState(false)
    const dispatch = useDispatch()
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [imageSrc, setImageSrc] = useState(null)
    const [disableButton, setDisableButton] = useState(false)
    const [otpDialog, setOtpDialog] = useState(false)
    const fileInputRef = useRef(null);

    const topupSchema = Yup.object().shape({
        amount: Yup.number().required("amount is required"),
        otherAmount: Yup.number(),
        proof: Yup.string().nullable()
    })

    const topupForm = useFormik({
        initialValues: initialTopup,
        validationSchema: topupSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            setDisableButton(true)
            setOtpDialog(false)
            if (imageSrc !== null) {
                values.proof = imageSrc
                API.post('/transaction/topup', values).then((topupRes) => {
                    if (topupRes.status === 200) {
                        dispatch(setAlert({ title: 'Success', subtitle: 'Topup Request Submitted Successfully', active: true, type: 'success' }))
                        topupForm.resetForm()
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        setOpenConfirmDialog(false)
                        setOtherAmount(false)
                        setDisableButton(false)
                    }
                })
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Please upload the proof', active: true, type: 'error' }))
            }
        }
    })

    const setTopupAmount = (value) => {
        if (value === 'Other') {
            setOtherAmount(true)
        } else {
            setOtherAmount(false)
            topupForm.setValues({ ...topupForm.values, amount: value })
        }
    }

    const uploadFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result); // Set the Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            dispatch(setAlert({ title: 'Error', subtitle: 'No file selected', active: true, type: 'error' }));
        }
    };

    // const requestOTP = () => {
    //     // topupForm.submitForm()
    //     API.get('/common/requestOTP').then((otpRes) => {
    //         console.log(otpRes)
    //         setOpenConfirmDialog(false)
    //         setOtpDialog(true)
    //     })
    // }

    return (
        <motion.div className="relative gap-5 h-full w-full flex flex-col-reverse md:flex-row justify-between">
            <motion.div className="bg-white w-full md:w-3/5 rounded-2xl my-5 px-2 py-5">
                {/* <FieldInput label="Proof" type="file" fieldProps={topupForm.getFieldProps('proof')} fieldMeta={topupForm.getFieldMeta('proof')} fieldHelper={topupForm.getFieldHelpers('proof')} edit={true} dataLoaded={true} onChange={(ev) => uploadFile(ev)} /> */}
                <motion.div className={`relative mt-2 mb-2 p-5`}>
                    <motion.div className={`absolute -top-3 left-2 drop-shadow-sm rounded w-auto text-tPColor px-5`}>
                        Proof
                    </motion.div>
                    <input ref={fileInputRef} type="file" accept={'.png, .jpeg, .jpg'} onChange={(ev) => uploadFile(ev)} />
                </motion.div>
                <motion.div className="flex flex-col px-5 my-2">
                    <motion.div className="text-md">Select Amount</motion.div>
                    <motion.div className="flex flex-row flex-wrap">
                        {
                            amounts.map((amount, index) => {
                                return (
                                    <motion.div className={`px-4 mx-2 my-2 cursor-pointer ${topupForm.values.amount === amount.value ? 'bg-nameColor/20 text-nameColor' : 'bg-slate-200'} hover:text-nameColor hover:bg-nameColor/20 rounded-full py-1`} onClick={() => setTopupAmount(amount.value)}>
                                        {amount.text}
                                    </motion.div>
                                )
                            })
                        }
                    </motion.div>
                </motion.div>
                {otherAmount && <FieldInput label="Other Amount" fieldProps={topupForm.getFieldProps('otherAmount')} fieldMeta={topupForm.getFieldMeta('otherAmount')} fieldHelper={topupForm.getFieldHelpers('otherAmount')} edit={true} dataLoaded={true} />}
                <motion.div className='flex flex-row justify-center'>
                    <Button outerWidth={'w-96'} injectClass={`${(topupForm.values.amount === 0) ? 'bg-nameColor/40 rounded-full cursor-not-allowed' : 'bg-nameColor rounded-full cursor-pointer'} text-black`} onClick={() => topupForm.values.amount === 0 || imageSrc === null ? dispatch(setAlert({ title: 'Error', subtitle: 'Please upload the proof', active: true, type: 'error' })) : setOpenConfirmDialog(true)}>Topup</Button>
                </motion.div>
            </motion.div>
            <motion.div className="bg-white w-full md:w-2/5 rounded-2xl flex flex-col my-5 px-2 py-5">
                <motion.div className="text-md my-2 px-2 py-2 text-black">
                    Scan the Qr code using binance/wazirX to topup your account
                </motion.div>
                <motion.div className='flex flex-col justify-center items-center'>
                    <img src={qrCode} height={200} width={200} />
                </motion.div>
                <motion.div className="flex flex-row my-2">
                    Free Coin Address : 0x88310d6F340EA142f5963502fBC89ecf20465951
                </motion.div>
            </motion.div>
            <Dialog title={'Staking Confirmation'} injectClass='w-[400px]' showDialog={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <motion.div className={`flex flex-col relative w-full h-full`}>
                    <motion.div className={'flex flex-row'}>
                        Are you sure, you are staking <span className='mx-2 text-xl font-bold'>${topupForm.values.amount === 'Other' ? topupForm.values.otherAmount : topupForm.values.amount}</span>
                    </motion.div>
                    <motion.div className={`flex flex-row justify-between`}>
                        <Button outerWidth={'w-96'} injectClass={'bg-nameColor/10 rounded px-5 py-1 w-fit cursor-pointer text-black'} onClick={() => { topupForm.resetForm(); setOpenConfirmDialog(false) }}>No</Button>
                        <Button outerWidth={'w-96'} injectClass={`${!disableButton ? 'bg-nameColor ' : 'bg-nameColor/10'}rounded px-5 py-1 w-fit cursor-pointer`} onClick={() => !disableButton && topupForm.submitForm()}>Yes</Button>
                    </motion.div>
                </motion.div>
            </Dialog>
            {/* <Dialog title={"Confirm Verification"} injectClass='w-[450px]' showDialog={otpDialog} onClose={() => { topupForm.resetForm(); setOtpDialog(false) }}>
                <OTPField onSucess={(ev) => ev && topupForm.submitForm()} onAbort={() => topupForm.resetForm()} />
            </Dialog> */}
        </motion.div>
    )
}

export default Staking